<section id="content">
    <figure [ngStyle]="{ 'background-image': 'url(./assets/images/spor-kulubu.jpg)' }">
        <div [ngClass]="'breadcrumb'">
            <h1>SPOR KULÜBÜMÜZ</h1>
            <ul id="breadcrumb" itemscope itemtype="http://schema.org/BreadcrumbList">
                <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item" routerLink="/" title="Ana Sayfa"><span  itemprop="name">Ana Sayfa</span></a>
                    <meta itemprop="position" content="1" />
                </li>
                <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
                    <meta itemprop="position" content="Spor Kulübümüz" />Spor Kulübümüz</li>
            </ul>
        </div>
    </figure>
    <div id="article-text" [ngClass]="'services'" class="list-group">
        <div [ngClass]="'srv'" class="list-group-item services" *ngFor="let spor of sporKulubu; let i = index" [class.active]="i == currentIndex">

            <figure [ngStyle]="{ 'background-image': 'url(data:image/jpeg;base64,'+spor.gorselKucukBase64+')' }"></figure>
            <content>
                <h2 [innerHTML]="spor.sporKulubuBaslik">{{spor.sporKulubuBaslik}}</h2>
                <div [innerHTML]="spor.sporKulubuOzet">{{spor.sporKulubuOzet}}</div>
            </content>
            <div>
                <a [routerLink]="['/spor-kulubu/spor-kulubu-detay', spor.permalink]" href="/spor-kulubu/spor-kulubu-detay/{{spor.permalink}}">Detaylar</a>
            </div>
        </div>
    </div>
</section>