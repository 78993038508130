import { Injectable } from '@angular/core';
import { Kurumsal } from '../kurumsal/kurumsal';
import { HttpClient, HttpParams } from '@angular/common/http'; //Adım 2
import { Observable } from 'rxjs'; //Adım 3
import { map,catchError,tap } from "rxjs/operators"; //Adım 4
import { GlobalConstants } from '../../common/global-constansts';


@Injectable({
  providedIn: 'root'
})
export class KurumsalService {

  baseURL:string='';

  constructor(private httpClient: HttpClient) {

    this.baseURL=GlobalConstants.apiURL+'/getKurumsal';
    console.log(GlobalConstants.apiURL);

   }



  read(id): Observable<any> {
    const options = { params: new HttpParams({fromString: "id="+id}) };
    return this.httpClient.get(this.baseURL,options);
  }

  getKurumsal():Observable<any>{
    
    return this.httpClient.get(this.baseURL);


  }



}