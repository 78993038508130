<section id="content">
    <figure [ngStyle]="{ 'background-image': 'url(data:image/jpeg;base64,'+ currentUrunlerDetay.gorselBuyukBase64+')' }">
        <div [ngClass]="'breadcrumb'">
            <h1>{{currentUrunlerDetay.urunBaslik}}</h1>
            <ul id="breadcrumb" itemscope itemtype="http://schema.org/BreadcrumbList">
                <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item" routerLink="/" title="Ana Sayfa"><span  itemprop="name">Ana Sayfa</span></a>
                    <meta itemprop="position" content="1" />
                </li>
                <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item" routerLink="/urunler" title="Ürünler"><span  itemprop="name">Ürünler</span></a>
                    <meta itemprop="position" content="2" />
                </li>
                <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><meta itemprop="position" content="3" />{{currentUrunlerDetay.urunBaslik}}</li>
            </ul>
        </div>
    </figure>
    <div id="article-text" [innerHTML]="currentUrunlerDetay.urunMetni">
        {{currentUrunlerDetay.urunMetni}}
    </div>
</section>