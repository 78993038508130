import { Injectable } from '@angular/core';
import { Haberler } from '../haberler/haberler';
import { HttpClient, HttpParams } from '@angular/common/http'; //Adım 2
import { Observable } from 'rxjs'; //Adım 3
import { map,catchError,tap } from "rxjs/operators"; //Adım 4
import { GlobalConstants } from '../../common/global-constansts';

@Injectable({
  providedIn: 'root'
})
export class HaberlerService {

  baseURL:string='';

  constructor(private httpClient: HttpClient) { 
    this.baseURL=GlobalConstants.apiURL+'/getHaberler';
    console.log(GlobalConstants.apiURL);
  }

  readAll(): Observable<Haberler[]> {
    return this.httpClient.get<Haberler[]>(this.baseURL);
  }
}