<section id="production">
	<div class="inside">
		<!--h1>ÜRETİM / HİZMETLERİMİZ</h1>
		<ul>
			<li>
				<img src="./assets/images/uretim-lazer.jpg" alt="Lazer, Optik ve Plazma Kesim">
				<h2>OPTİK, PLAZMA ve LAZER</h2>
				<span>Lazer, optik ve plazma kesim makinaları ile 1500mm x 3000mm, 2000mm x 4000mm, 2500mm x 6000mm ve 3000mm x 12000mm ebatlarında ve 180mm kalınlığa kadar boru, profil ve sac kesim imkânı.</span>
			</li>
			<li>
				<img src="./assets/images/uretim-makas.jpg" alt="Makas Kesim">
				<h2>CNC MAKAS</h2>
				<span>13mm kalınlık, 300 ton ve 4m boy ile 6mm kalınlık, 150 ton ve 3m boyunda kesim imkânı.</span>
			</li>
			<li>
				<img src="./assets/images/uretim-bukum.jpg" alt="">
				<h2>CNC ABKAND</h2>
				<span>12mm kalınlık, 400 ton ve 4m boyunda, 6mm kalınlık, 150 ton ve 3m boyunda, 6mm kalınlık, 60 ton ve 2m boyunda büküm imkânı.</span>
			</li>
			<li>
				<img src="./assets/images/uretim-kaynak.jpg" alt="">
				<h2>KAYNAK</h2>
				<span>Argon ve gaz altı kaynak makineleri ile ince, kaliteli ve detaylı kaynak hizmeti.</span>
			</li>
		</ul-->
	</div>
	<div class="video-cover"></div>
	<video autoplay muted loop id="myVideo" oncanplay="this.play()" onloadedmetadata="this.muted = true">
		<source src="./assets/images/mertse-main.mp4?autoplay=1" type="video/mp4">
	</video>
</section>