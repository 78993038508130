import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'; //Adım 2
import { Observable } from 'rxjs'; //Adım 3
import { map,catchError,tap } from "rxjs/operators"; //Adım 4
import { GlobalConstants } from '../../common/global-constansts';
import { Slider } from '../slider/Slider';

@Injectable({
  providedIn: 'root'
})
export class SliderService {
  baseURL:string='';


  constructor(private httpClient: HttpClient) { 
    this.baseURL=GlobalConstants.apiURL+'/getSlider';
    console.log(GlobalConstants.apiURL);
  }

  readAll(): Observable<Slider[]> {
    return this.httpClient.get<Slider[]>(this.baseURL);
  }
}
