import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UrunDetay } from 'src/app/components/urun-detay/urunDetay';
import { UrunDetayService } from '../services/urun-detay.service';
import { GlobalConstants } from 'src/app/common/global-constansts';




@Component({
  selector: 'app-urun-detay',
  templateUrl: './urun-detay.component.html',
  styleUrls: ['./urun-detay.component.css']
})
export class UrunDetayComponent implements OnInit {
  title='Mertse | Ürünler Detay'
  currentIndex = -1;
  currentUrunlerDetay:UrunDetay=new UrunDetay();

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private urunlerDetayServis: UrunDetayService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    console.log(GlobalConstants.apiURL);

   }

  ngOnInit(): void {
    this.readUrunDetay();
  }


  readUrunDetay(): void {

    const pln = this.route.snapshot.paramMap.get('permalink');

   this.urunlerDetayServis.getUrun(pln)
     .subscribe(
       data => {
         this.currentUrunlerDetay = data;
       },
       error => {
         console.log(error);
       });
 }

}
