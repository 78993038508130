import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { KullaniciModel } from 'src/app/models/kullanicimodel';
import { TeklifModel } from 'src/app/models/teklifModel';
import { PortalService } from 'src/app/services/portal.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-siparislerim',
  templateUrl: './siparislerim.component.html',
  styleUrls: ['./siparislerim.component.css'],
  providers: [MessageService]
})
export class SiparislerimComponent implements OnInit {

  title='Mertse | Siparişlerim'
  siparislerim:Array<TeklifModel>;
  kullanici:KullaniciModel;
  statuses: any[];


  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private portalSevis:PortalService,
    public tokenStorage: TokenStorageService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Bayi siparişlerinin listesi.' }
    );
    this.getSiparislerim();

    this.statuses = [
      {label: 'Bekliyor', value: 'bekliyor'},
      {label: 'Tamamlandı', value: 'tamamlandi'},
      {label: 'İptal Edildi', value: 'iptaledildi'},
      ]
  }

  getSiparislerim(){

    this.kullanici= this.tokenStorage.getUser();
    this.portalSevis.getSiparisler(this.kullanici.cariId).subscribe(
      data => {
        this.siparislerim=data;
      },
      error => {
        this.messageService.add({severity:'error', summary:'Hata', detail:error});
   
        console.log(error);
      });

  }
}
