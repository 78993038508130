import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MessageService,FilterService, FilterMatchMode  } from 'primeng/api';
import { KullaniciModel } from 'src/app/models/kullanicimodel';
import { PortalService } from 'src/app/services/portal.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-kullanici-ayarlari',
  templateUrl: './kullanici-ayarlari.component.html',
  styleUrls: ['./kullanici-ayarlari.component.css'],
  providers :[MessageService]
})
export class KullaniciAyarlariComponent implements OnInit {

  cariBilgi: KullaniciModel;

  title='Mertse | Kullanıcı Ayarları'

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private tokenservis: TokenStorageService,
    private protalServis: PortalService,
    private msg:MessageService,
    private filterService: FilterService
  ) { }

  ngOnInit() {
    this.cariBilgi=this.tokenservis.getUser();
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Bayi ve Kullanıcı Hesabının Giriş Bilgilerini Yönetin.' }
    );
  }

}