import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { HaberlerService } from '../services/haberler.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Haberler } from './haberler';
import { GlobalConstants } from 'src/app/common/global-constansts';

@Component({
  selector: 'app-haberler',
  templateUrl: './haberler.component.html',
  styleUrls: ['./haberler.component.css']
})
export class HaberlerComponent implements OnInit {
  
  haberler:Haberler[];
  title='Mertse | Haberler'
  currentIndex = -1;
  currentHaberler = {
    "id":"",
    "haberKucukResimPath":"",
    "haberKucukResimAdi":"",
    "haberBuyukResimPath":"",
    "haberBuyukResimAdi":"",
    "haberBaslik":"",
    "haberOzeti":"",
    "haberMetni":"",
    "haberTarih":"",
    "gorselKucukBase64":"",
    "gorselBuyukBase64":"",
    "permalink":""
  };  
    
    constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private haberlerServis: HaberlerService,
    private route: ActivatedRoute,
    private router: Router
    ) {
  
     }

  ngOnInit(): void {
    this.readHaberler();
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Bizden güncel haberler.' }
    );
  }
  
  readHaberler(): void {
    this.haberlerServis.readAll()
      .subscribe(
        data => {
          this.haberler = data;
        },
        error => {
        });
  }


}
