import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ResponseTypes } from 'src/app/enums/response-types.enum';
import { AnaStokGrup } from 'src/app/models/anastokgrup';
import { BaglantiTalep } from 'src/app/models/baglantiTalep';
import { FiyatIskonto } from 'src/app/models/fiyatIskonto';
import { KullaniciModel } from 'src/app/models/kullanicimodel';
import { OlcuBirim } from 'src/app/models/olcuBirim';
import { StokGrup } from 'src/app/models/stokGrup';
import { PortalService } from 'src/app/services/portal.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-baglanti-talep',
  templateUrl: './baglanti-talep.component.html',
  styleUrls: ['./baglanti-talep.component.css'],
  providers: [MessageService]
})
export class BaglantiTalepComponent implements OnInit {

  title='Mertse | Bağlantı Talebi';
 
  talep:BaglantiTalep;
  anaStokGruplari : AnaStokGrup[];
  anaStokGrup:AnaStokGrup;
  kullaniciModel:KullaniciModel;
  
  stokGruplari:StokGrup[];
  birimler:Array<OlcuBirim>;
  fiyatIskontolari:FiyatIskonto[];
  stokGrubuFiyatIskontolari:FiyatIskonto[];
  

  constructor( private titleService: Title,
    private metaTagService: Meta,
    public tokenStorage: TokenStorageService,
    private router: Router,
    private portalSevis:PortalService,
    private messageService: MessageService) { }

  ngOnInit(): void {
    if(!this.tokenStorage.isLogin){
      //login değilse ana sayfaya yönlendir.
      this.router.navigateByUrl('');
    }
    this.kullaniciModel=this.tokenStorage.getUser();
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: '1993 yılında Ekrem Senal tarafından kurularak 110 m² işyeri ile demir çelik sektöründeki yerini almıştır. Firma bugün 15.000 m² kare kapalı 10.000 m² açık alan olmak üzere toplam 25.000 m² alana çıkarak sektördeki ciddiyetini orataya koymuştur.' }
    );
    this.getAnaStokGruplari();
    this.talep=new BaglantiTalep();
    this.birimler=new Array<OlcuBirim>();
    this.getOlcuBirimleri();
    this.getFiyatIskontolari();
    this.stokGrubuFiyatIskontolari=new Array<FiyatIskonto>();

  }

  getOlcuBirimleri(){
    this.portalSevis.getBirimler().subscribe(
      data=>{
          this.birimler=data;
      },
      error=>{
        this.messageService.add({severity:'error', summary:'Hata', detail:'Hata oluştu.'+error});
      }
    );
  }

  getAnaStokGruplari():void{

    this.portalSevis.getAnaStokGruplari().subscribe(
     data => {
       this.anaStokGruplari = data;
     },
     error => {
      this.messageService.add({severity:'error', summary:'Hata', detail:error.error.text});
     });
 }

 anaStokDegisti(){
   this.portalSevis.getStokGruplari(this.anaStokGrup.id).subscribe(
     data => {
       this.stokGruplari = data;
     },
     error => {
        this.messageService.add({severity:'error', summary:'Hata', detail:error.error.text});
     });

 }

 stokGrubuDegisti(){
    this.stokGrubuFiyatIskontolari=new Array<FiyatIskonto>();
    this.fiyatIskontolari.forEach((value,index)=>{
      if(value.stokGrubuId==this.talep.stokGrubu.id) this.stokGrubuFiyatIskontolari.push(value);
    });
 }

 getFiyatIskontolari(){
  this.portalSevis.getIskontoListesi(this.kullaniciModel.cariId).subscribe(
    data=>{
      this.fiyatIskontolari=data;
    },
    error => {
      this.messageService.add({severity:'error', summary:'Hata', detail:error});
    });
}

  yeni(){
    this.talep=new BaglantiTalep();
  }

  kaydet(){

    if(this.talep.stokGrubu==null){
      this.messageService.add({severity:'warn', summary:'Uyarı', detail:'Stok Ana Grubu ve Stok Grubu giriniz!'});
      return;          
    }
    if(this.talep.birim==null){
      this.messageService.add({severity:'warn', summary:'Uyarı', detail:'Birim Seçiniz!'});
      return;          
    }

    if(this.talep.toplamMiktar==null){
      this.messageService.add({severity:'warn', summary:'Uyarı', detail:'Miktar Giriniz!'});
      return;          
    }

    if(this.talep.fiyatIskontoListesi==null){
      this.messageService.add({severity:'warn', summary:'Uyarı', detail:'Fiyat Listesi Seçiniz!'});
      return;          
    }

    this.talep.cariPersonelId=this.kullaniciModel.id;

    this.portalSevis.baglantiTalepOlustur(this.talep).subscribe(
      data=>{
        if(data.respType=='OK'){
          this.messageService.add({severity:'info', summary:'Bilgi', detail:'İşleminiz Başarı ile tamamlandı.'});          
          this.anaStokGrup=null;
          this.talep=new BaglantiTalep();
        }else{
          this.messageService.add({severity:'error', summary:'Hata', detail:'Hata oluştu.'});          
        }
      },
      error=>{
        this.messageService.add({severity:'error', summary:'Hata', detail:error.error.text});
      }
    );
  }


}
