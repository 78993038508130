import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UrunlerService } from '../services/urunler.service';
import { GlobalConstants } from 'src/app/common/global-constansts';
import { Urunler } from '../urunler/urunler';

@Component({
  selector: 'app-main-products',
  templateUrl: './main-products.component.html',
  styleUrls: ['./main-products.component.css']
})
export class MainProductsComponent implements OnInit {

  urunler:Urunler[];
  responsiveOptions;
  title='Mertse'
  currentIndex = -1;
  currentUurunler = {"id":"","gorselPath":"",
    "urunGorselAdi":"",
    "urunBaslik":"",
    "urunOzeti":"",
    "gorselBase64":"",
    "permalink":""}; 

    constructor(

      private urunlerServis: UrunlerService,
      private route: ActivatedRoute,
      private router: Router
  
    ) { 
      this.responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 4,
            numScroll: 1
        },
        {
            breakpoint: '768px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '560px',
            numVisible: 1,
            numScroll: 1
        }
    ];
      


    }

  ngOnInit(): void {
    this.readUrunler();
  }
  readUrunler(): void {
    this.urunlerServis.readAll()
      .subscribe(
        data => {
          this.urunler = data;
        },
        error => {
        });
  }

}
