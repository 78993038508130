<div [ngClass]="'header-bg'"></div>
<p-toast position="center"></p-toast>   
<section>
    <div [ngClass]="'my-offers-area'">
        <app-cari-bilgi></app-cari-bilgi>
        <div [ngClass]="'my-offers-list'">
            <table>
                <ng-container *ngFor="let teklif of tekliflerim">
                    <tr>
                        <td>
                            <table class="teklif-baslik">
                                <tr>
                                    <th>
                                        <h3>Teklif Durumu</h3>
                                    </th>
                                    <th>
                                        <h3>Teklif tarihi</h3>
                                    </th>
                                    <th>
                                        <h3>Müşteri Teklif No</h3>
                                    </th>
                                    <th>
                                        <h3>Mertse Teklif No</h3>
                                    </th>
                                    <th>
                                        <h3>Mertse Sipariş No</h3>
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <p>
                                            <span *ngIf="teklif.teklifeDonumstumu  && !teklif.sipariseDonumstumu">
                                            <button pButton pRipple (click)="sipariseDonustur(teklif.id)"><i class="pi pi-check" title="Teklif Onaylandı"></i></button>
                                            </span>
                                        </p>
                                        <p>
                                            <span *ngIf="teklif.teklifeDonumstumu  && teklif.sipariseDonumstumu"><i class="pi pi-replay" title="Siparişe Dönüştürüldü"></i></span>
                                        </p>
                                        <p>
                                            <span *ngIf="!teklif.teklifeDonumstumu"><i class="pi pi-spin pi-spinner" title="Bekliyor"></i></span>
                                        </p>
                                    </td>
                                    <td>
                                        <p>{{teklif.tarih | date:'dd/MM/yyyy'}}</p>
                                    </td>
                                    <td>
                                        <p>{{teklif.teklifKodu==null||''?'----':teklif.teklifKodu}}</p>
                                    </td>
                                    <td>
                                        <p>{{teklif.teklifNo==null||''?'----':teklif.teklifNo}}</p>
                                    </td>
                                    <td>
                                        <p>{{teklif.siparisNo==null||''?'----':teklif.siparisNo}}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <h3>Açıklama</h3>
                                    </th>
                                    <th>
                                        <h3>Sevkiyat Tipi</h3>
                                    </th>
                                    <th>
                                        <h3>Tutar</h3>
                                    </th>
                                    <th>
                                        <h3>KDV</h3>
                                    </th>
                                    <th>
                                        <h3>Toplam</h3>
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <p>{{teklif.teklifAciklama}}</p>
                                    </td>
                                    <td>
                                        
                                        <p>{{teklif.musteriAlacakMi?'Kendi Aracımız':'Mertse Aracı'}}</p>
                                    </td>
                                    <td>
                                        
                                        <p>{{teklif.tutar| number:'.2-2'}}₺</p>
                                    </td>
                                    <td>
                                        
                                        <p>{{teklif.kdv| number:'.2-2'}}₺</p>
                                    </td>
                                    <td>
                                        
                                        <p>{{teklif.genelToplam| number:'.2-2'}}₺</p>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td>
                            <table>
                                <tr class="teklif-detay-header">
                                    <td>Stok Kodu</td>
                                    <td>Stok Adı</td>
                                    <td>Adet</td>
                                    <td>Ağırlık</td>
                                    <td>Uzunluk</td>
                                    <td>Adet Fiyatı</td>
                                    <td>Kg Fiyatı</td>
                                    <td>Mt. Fiyatı</td>
                                </tr>
                                <ng-container *ngFor="let stok of teklif.stokLar">
                                    <tr class="teklif-detay">
                                        <td>{{stok.cariStok.kodu}}</td>
                                        <td>{{stok.cariStok.adi}}</td>
                                        <td>{{stok.adet}}</td>
                                        <td>{{stok.kgMiktar}}</td>
                                        <td>{{stok.mtMiktar}}</td>
                                        <td>{{stok.adBirimFiyat| number:'.2-2'}}₺</td>
                                        <td>{{stok.kgBirimFiyat | number:'.2-2'}}₺</td>
                                        <td>{{stok.mtBirimFiyat | number:'.2-2'}}₺</td>
        
                                    </tr>   
                                </ng-container>
                            </table>
                        </td>
                    </tr>

                        
                </ng-container>
            </table>
            <button label="Bayi Ana Sayfası" pButton pRipple (click)="geridon()" icon="pi pi-undo" class="p-button-warning" style="color: #fff;"></button>                  
        </div>
    </div>
</section>