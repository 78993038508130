import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'; //Adım 2
import { Observable } from 'rxjs'; //Adım 3
import { map,catchError,tap } from "rxjs/operators"; //Adım 4
import { Urunler } from '../urunler/urunler';
import { GlobalConstants } from '../../common/global-constansts';


//const baseURL = 'http://localhost:8080/metalerp/metal/web/getUrunler';


@Injectable({
  providedIn: 'root'
})
export class UrunlerService {
  
  baseURL:string='';


  constructor(private httpClient: HttpClient) { 
    this.baseURL=GlobalConstants.apiURL+'/getUrunler';
    console.log(GlobalConstants.apiURL);
  }

    
  readAll(): Observable<Urunler[]> {
    return this.httpClient.get<Urunler[]>(this.baseURL);
  }

}
