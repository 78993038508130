import { FiyatIskonto } from "./fiyatIskonto";
import { OlcuBirim } from "./olcuBirim";
import { StokGrup } from "./stokGrup";

export class BaglantiTalep{

    cariPersonelId:number;	
	cariPersonel:string;	
	stokGrubu:StokGrup;
    birim:OlcuBirim;
	toplamMiktar:number;
    saticiOnay :boolean;
	musteriOnay:boolean;
    musteriAciklama:string;
    saticiAciklama:string;
    fiyatIskontoListesi:FiyatIskonto;
    talepTarihi:Date;
    saticiOnayTarihi:Date;
    musteriOnayTarihi:Date;
    baglantiNumarasi:string;
    baglantiId:number; 

}