<section id="our-products" >
    <div class="inside">
        <section id="product-slider" class="opd">
            <h1>ÜRÜNLERİMİZ</h1>
            <p-carousel [(value)]="urunler" [numVisible]="4" [numScroll]="1"  [showIndicators]="false" [showNavigators]="true" [responsiveOptions]="responsiveOptions" [autoplayInterval]="5000" [circular]="false">
                <ng-template let-urun of urunler pTemplate="urun">

                    <div class="urunler-item services prods">
                        <img src="{{'data:image/jpg;base64,' + urun.gorselKucukBase64}}" />

                        <h2 [innerHTML]="urun.urunBaslik">{{urun.urunBaslik}}</h2>
                        <span [innerHTML]="urun.urunOzeti">{{urun.urunOzeti}}</span>
                        <a [routerLink]="[ '/urunler/urun-detay', urun.permalink]" href="/urunler/urun-detay/{{urun.permalink}}" title="{{urun.urunBaslik}}"></a>
                    </div>

                </ng-template>
            </p-carousel>
        </section>

        <section id="product-slider" class="opm">
            <h1>ÜRÜNLERİMİZ</h1>
            <p-carousel [value]="urunler" [numVisible]="1" [numScroll]="1" [showIndicators]="false" [showNavigators]="true" [responsiveOptions]="responsiveOptions" [autoplayInterval]="5000" [circular]="false">
                <ng-template let-urun of urunler pTemplate="urun">

                    <div class="urunler-item services prods">
                        <img src="{{'data:image/jpg;base64,' + urun.gorselKucukBase64}}" />

                        <h2 [innerHTML]="urun.urunBaslik">{{urun.urunBaslik}}</h2>
                        <span [innerHTML]="urun.urunOzeti">{{urun.urunOzeti}}</span>
                        <a [routerLink]="[ '/urunler/urun-detay', urun.permalink]" href="/urunler/urun-detay/{{urun.permalink}}" title="{{urun.urunBaslik}}"></a>
                    </div>

                </ng-template>
            </p-carousel>
        </section>

        <section id="product-slider" class="opt">
            <h1>ÜRÜNLERİMİZ</h1>
            <p-carousel [value]="urunler" [numVisible]="2" [numScroll]="1" [showIndicators]="false" [showNavigators]="true" [responsiveOptions]="responsiveOptions" [autoplayInterval]="5000" [circular]="false">
                <ng-template let-urun of urunler pTemplate="urun">

                    <div class="urunler-item services prods">
                        <img src="{{'data:image/jpg;base64,' + urun.gorselKucukBase64}}" />

                        <h2 [innerHTML]="urun.urunBaslik">{{urun.urunBaslik}}</h2>
                        <span [innerHTML]="urun.urunOzeti">{{urun.urunOzeti}}</span>
                        <a [routerLink]="[ '/urunler/urun-detay', urun.permalink]" href="/urunler/urun-detay/{{urun.permalink}}" title="{{urun.urunBaslik}}"></a>
                    </div>

                </ng-template>
            </p-carousel>
        </section>

        <section id="product-slider" class="opt2">
            <h1>ÜRÜNLERİMİZ</h1>
            <p-carousel [value]="urunler" [numVisible]="2" [numScroll]="1" [showIndicators]="false" [showNavigators]="true" [responsiveOptions]="responsiveOptions" [autoplayInterval]="5000" [circular]="false">
                <ng-template let-urun of urunler pTemplate="urun">

                    <div class="urunler-item services prods">
                        <img src="{{'data:image/jpg;base64,' + urun.gorselKucukBase64}}" />

                        <h2 [innerHTML]="urun.urunBaslik">{{urun.urunBaslik}}</h2>
                        <span [innerHTML]="urun.urunOzeti">{{urun.urunOzeti}}</span>
                        <a [routerLink]="[ '/urunler/urun-detay', urun.permalink]" href="/urunler/urun-detay/{{urun.permalink}}" title="{{urun.urunBaslik}}"></a>
                    </div>

                </ng-template>
            </p-carousel>
        </section>
    </div>
</section>