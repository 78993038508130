import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BayiComponent } from './components/bayi/bayi.component';
import { BayilbComponent } from './components/bayilb/bayilb.component';
import { ContactComponent } from './components/contact/contact.component';
import { HakkimizdaComponent } from './components/hakkimizda/hakkimizda.component';
import { HizmetDetayComponent } from './components/hizmet-detay/hizmet-detay.component';
import { HizmetlerimizComponent } from './components/hizmetlerimiz/hizmetlerimiz.component';
import { HomeComponent } from './components/home/home.component';
import { KurumsalComponent } from './components/kurumsal/kurumsal.component';
import { MakineParkuruComponent } from './components/makine-parkuru/makine-parkuru.component';
import { MisyonVizyonComponent } from './components/misyon-vizyon/misyon-vizyon.component';
import { OrganizasyonSemasiComponent } from './components/organizasyon-semasi/organizasyon-semasi.component';
import { UrunlerComponent } from './components/urunler/urunler.component';
import { TeklifIsteComponent } from './components/teklif-iste/teklif-iste.component';
import { TekliflerimComponent } from './components/tekliflerim/tekliflerim.component';
import { SiparislerimComponent } from './components/siparislerim/siparislerim.component';
import { KullaniciAyarlariComponent } from './components/kullanici-ayarlari/kullanici-ayarlari.component';
import { HesapHareketleriComponent } from './components/hesap-hareketleri/hesap-hareketleri.component';
import { UrunDetayComponent } from './components/urun-detay/urun-detay.component';
import { SporKulubuDetayComponent } from './components/spor-kulubu-detay/spor-kulubu-detay.component';
import { HaberDetayComponent } from './components/haber-detay/haber-detay.component';
import { HaberlerComponent } from './components/haberler/haberler.component';
import { SporKulubuComponent } from './components/spor-kulubu/spor-kulubu.component';
import { GizlilikVeKisiselVerilerinKorunmasiPolitikasiComponent } from './gizlilik-ve-kisisel-verilerin-korunmasi-politikasi/gizlilik-ve-kisisel-verilerin-korunmasi-politikasi.component';
import { KullanimKosullariComponent } from './kullanim-kosullari/kullanim-kosullari.component';
import { HesaplamaSacComponent } from './components/hesaplama-sac/hesaplama-sac.component';
import { BaglantiTalepComponent } from './components/baglanti-talep/baglanti-talep.component';
import { BaglantiTaleplerimComponent } from './components/baglanti-taleplerim/baglanti-taleplerim.component';
import { BaglantiSiparisComponentComponent } from './components/baglanti-siparis-component/baglanti-siparis-component.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'kurumsal',
    component: KurumsalComponent
  },
  {
    path: 'bayi',
    component: BayiComponent
  },
  {
    path: 'login',
    component: BayilbComponent
  },
  {
    path: 'kurumsal/hakkimizda',
    component: HakkimizdaComponent
  },
  {
    path: 'kurumsal/misyon-vizyon',
    component: MisyonVizyonComponent
  },
  {
    path: 'kurumsal/makine-parkurumuz',
    component: MakineParkuruComponent
  },
  {
    path: 'kurumsal/organizasyon-semasi',
    component: OrganizasyonSemasiComponent
  },
  {
    path: 'hizmetlerimiz',
    component: HizmetlerimizComponent
  },
  {
    path:'hizmetlerimiz/hizmetDetay/:permalink',
    component:HizmetDetayComponent
  },

  {
    path:'urunler/urun-detay/:permalink',
    component:UrunDetayComponent
  },
  
  {
    path: 'urunler',
    component: UrunlerComponent
  },

  {
    path: 'spor-kulubu',
    component: SporKulubuComponent
  },
  {
    path: 'spor-kulubu/spor-kulubu-detay/:permalink',
    component: SporKulubuDetayComponent
  },
  {
    path: 'haberler',
    component: HaberlerComponent
  },
  {
    path: 'haberler/haber-detay/:permalink',
    component: HaberDetayComponent
  },

  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'bayilb',
    component: BayilbComponent
  },
  {
    path: 'bayi/teklif-iste',
    component: TeklifIsteComponent
  },
  {
    path: 'bayi/tekliflerim',
    component: TekliflerimComponent
  },
  {
    path: 'bayi/siparislerim',
    component: SiparislerimComponent
  },
  {
    path: 'bayi/kullanici-ayarlari',
    component: KullaniciAyarlariComponent
  },

  {
    path: 'bayi/hesap-hareketleri',
    component: HesapHareketleriComponent
  },

  {
    path: 'gizlilik-ve-kisisel-verilerin-korunmasi-politikasi',
    component: GizlilikVeKisiselVerilerinKorunmasiPolitikasiComponent
  },

  {
    path: 'kullanim-kosullari',
    component: KullanimKosullariComponent
  },

  {
    path: 'sac-agirlik-hesaplama',
    component: HesaplamaSacComponent 
  },
  {
    path: 'bayi/baglanti-talep',
    component: BaglantiTalepComponent
  },
  {
    path: 'bayi/baglanti-taleplerim',
    component: BaglantiTaleplerimComponent
  },
  {
    path: 'bayi/baglanti-siparis',
    component: BaglantiSiparisComponentComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
