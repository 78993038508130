import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http'; //Adım 2
import { Observable } from 'rxjs'; //Adım 3
import { KullaniciModel } from '../models/kullanicimodel';
import { GlobalConstants } from '../common/global-constansts';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  baseURL:string='';

  constructor(private httpClient: HttpClient) {

    this.baseURL=GlobalConstants.apiURL+'/login';

   }

   login(kullanici:KullaniciModel): Observable<any> {
      return this.httpClient.post(this.baseURL,kullanici);
  }
}
