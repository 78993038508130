<footer>
    <div [ngClass]="'inside'">
        <ul>
            <li><span>© {{ fthscDate }} Mertse Sac Metal. Tüm Hakkı Saklıdır.</span></li>
            <li><span><a href="https://www.codemine.com.tr" target="_blank"><img src="/assets/images/codemine-logo-white.png" alt="Codemine Metal ERP Entegrasyonu" title="Codemine Metal ERP Entegrasyonu"></a> Metal ERP Entegrasyonu</span></li>
            <li><a [routerLink]="['/gizlilik-ve-kisisel-verilerin-korunmasi-politikasi']" href="/gizlilik-ve-kisisel-verilerin-korunmasi-politikasi" title="Gizlilik ve Kişisel Verilerin Korunması Politikası">Gizlilik ve Kişisel Verilerin Korunması Politikası</a> <span>/</span> <a [routerLink]="['/kullanim-kosullari']" href="/kullanim-kosullari" title="Kullanım Koşulları">Kullanım Koşulları</a></li>
        </ul>
    </div>
    <div [ngClass]="'whatsapp'">
      <button (click)="toggleShow()" type="checkbox"><img src="/assets/images/whatsapp.png" alt="Whatsapp Satış / Destek Hattı" title="Whatsapp Satış / Destek Hattı"></button>
      <div class="whatsapp-inside row container-fluid" *ngIf="isShown" id="divshow">
        <div [ngClass]="'head'"><h3>Whatsapp Satış / Destek Hattı</h3></div>
        <a href="https://api.whatsapp.com/send?phone=905353052807" target="_blank" title="Boru Profil - Hadde Satış"><img src="/assets/images/whatsapp.png" alt="Whatsapp Satış / Destek Hattı" title="Whatsapp Satış / Destek Hattı">Boru Profil - Hadde Satış</a>
        <a href="https://api.whatsapp.com/send?phone=905394269969" target="_blank" title="Sac Satış ve Makas - Abkant Üretim"><img src="/assets/images/whatsapp.png" alt="Whatsapp Satış / Destek Hattı" title="Whatsapp Satış / Destek Hattı">Sac Satış ve Makas - Abkant Üretim</a>
        <a href="https://api.whatsapp.com/send?phone=905559601237" target="_blank" title="Lazer, Plazma ve Oksijen Kesim Üretim"><img src="/assets/images/whatsapp.png" alt="Whatsapp Satış / Destek Hattı" title="Whatsapp Satış / Destek Hattı">Lazer, Plazma ve Oksijen Kesim Üretim</a>
      </div>
    </div>

</footer>

<script type="application/ld+json">
    {
     "@context": "http://schema.org",
     "@type": "Organization",
     "url": "https://www.mertse.com",
           "sameAs": [
             "https://www.facebook.com/",
             "https://www.twitter.com/",
             "https://www.instagram.com/",
             "https://www.youtube.com/",
             "https://linkedin.com/"
           ],
     "name":"Mertse Sac Metal,
     "contactPoint": [
    { "@type": "ContactPoint",
     "telephone": "+902244941521",
     "contactType": "sales"
    }
     ],
       "address": {
         "@type": "PostalAddress",
         "addressLocality": "Bursa / Türkiye",
         "streetAddress": "Demirci Mh. Dere Sk. No:102 Nilüfer"
       },
       "logo":"//www.mertse.com/images/logo.png",
       "description":"1993 yılında Ekrem Senal tarafından kurularak 110 metrekarelik işyeri ile demir çelik sektöründeki yerini almıştır. Firma bugün 15.000 m2 kare kapalı 10.000 m2 açık alan olmak üzere toplam 25.000 m2 alana çıkarak sektördeki ciddiyetini orataya koymuştur.",
       "email": "mailto:mertse@mertse.com"
       }
</script>