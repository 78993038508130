import { Component, OnInit } from '@angular/core';
import { MessageService,FilterService, FilterMatchMode  } from 'primeng/api';
import { FiyatIskonto } from 'src/app/models/fiyatIskonto';
import { KullaniciModel } from 'src/app/models/kullanicimodel';
import { PortalService } from 'src/app/services/portal.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';


@Component({
  selector: 'app-cari-bilgi',
  templateUrl: './cari-bilgi.component.html',
  styleUrls: ['./cari-bilgi.component.css'],
  providers :[MessageService]
})
export class CariBilgiComponent implements OnInit {

  cariBilgi: KullaniciModel;
  fiyatIskontolari:FiyatIskonto[];

  constructor(
    private tokenservis: TokenStorageService,
    private protalServis: PortalService,
    private msg:MessageService,
    private filterService: FilterService
  ) { }

  ngOnInit(): void {
    this.cariBilgi=this.tokenservis.getUser();
    this.getFiyatIskontolari();
/*
    this.filterService.register(
      FilterMatchMode.CONTAINS ,
      (value, filter): boolean => {
        if (filter === undefined || filter === null || filter.trim() === "") {
          return true;
        }

        if (value === undefined || value === null) {
          return false;
        }

        return value
      }
    );
  */
  }

  getFiyatIskontolari(){
    this.protalServis.getIskontoListesi(this.cariBilgi.cariId).subscribe(
      data=>{
        this.fiyatIskontolari=data;
      },
      error => {
        this.msg.add({severity:'error', summary:'Hata', detail:error});
      });
  }

}
