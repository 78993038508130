import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UrunlerService } from '../services/urunler.service';
import { Urunler } from './urunler';
import { GlobalConstants } from 'src/app/common/global-constansts';

@Component({
  selector: 'app-urunler',
  templateUrl: './urunler.component.html',
  styleUrls: ['./urunler.component.css']
})
export class UrunlerComponent implements OnInit {

  urunler:Urunler[];
  title='Mertse | Ürünler'
  currentIndex = -1;
  currentUurunler = {
    "id":"",
    "urunUstGorselPath":"",
    "urunUstGorselAdi":"",
    "urunListeGorselPath":"",
    "urunListeGorselAdi":"",
    "urunBaslik":"",
    "urunOzeti":"",
    "urunMetni":"",
    "urunTitle":"",
    "urunMetaDesc":"",
    "gorselBuyukBase64":"",
    "gorselKucukBase64":"",
    "permalink":""}; 



  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private urunlerServis: UrunlerService,
    private route: ActivatedRoute,
    private router: Router

  ) { 

  }

  ngOnInit() {
    this.readUrunler();
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Satışını yaptığımız tüm ürünler.' }
    );
  }

  readUrunler(): void {
    this.urunlerServis.readAll()
      .subscribe(
        data => {
          this.urunler = data;

        },
        error => {
        });
  }

}
