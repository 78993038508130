import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'; //Adım 2
import { Observable } from 'rxjs'; //Adım 3
import { map,catchError,tap } from "rxjs/operators"; //Adım 4
import { UrunDetay } from 'src/app/components/urun-detay/UrunDetay';
import { GlobalConstants } from '../../common/global-constansts';


//const baseURL = 'http://localhost:8080/metalerp/metal/web/getUrun';


@Injectable({
  providedIn: 'root'
})
export class UrunDetayService {
  baseURL:string='';

  constructor(private httpClient: HttpClient) {
    
    this.baseURL=GlobalConstants.apiURL+'/getUrun';
    console.log(GlobalConstants.apiURL);
   }

  

  getUrun(permalink): Observable<UrunDetay> {
    const options = { params: new HttpParams({fromString: "permalink="+permalink}) }; 
    return this.httpClient.get<UrunDetay>(this.baseURL,options);
  }

  readAll(): Observable<any> {
    return this.httpClient.get(this.baseURL);
  }
  
}
