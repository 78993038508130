<div [ngClass]="'header-bg'"></div>

<section>
    <p-toast  position="center"></p-toast>   
    <div [ngClass]="'offer-area'">
        <h1>BAYİ / MÜŞTERİ TEKLİF OLUŞTURMA</h1>
        <app-cari-bilgi></app-cari-bilgi>

        <ul>
            
            <li>
                <h1>Bağlantı</h1>
            </li>
            <li>
                <p-dropdown [options]="baglantilar" [(ngModel)]="baglanti" placeholder="Bağlantı Seçiniz" optionLabel="adi" [showClear]="true" (onChange)="baglantiDegisti()"></p-dropdown>
            </li>
            
            <li>
                <h1>Ana Stok Grubu</h1>
            </li>
            <li>
                <p-dropdown [options]="anaStokGruplari" [(ngModel)]="anaStokGrup" placeholder="Stok Ana Grubu Seçiniz" optionLabel="adi" [showClear]="true" (onChange)="anaStokDegisti()"></p-dropdown>
            </li>

            <li>
                <h1>Stok Grubu</h1>
            </li>            
            <li>
                <p-dropdown [options]="stokGruplari" [(ngModel)]="stokGrup" placeholder="Stok Grubu Seçiniz" optionLabel="adi" [showClear]="true" [filter]="true"></p-dropdown>
            </li>

            <li>
                <h1>Kalite</h1>
            </li>
            <li>
                <p-dropdown [options]="anaKaliteGruplari" [(ngModel)]="anaKaliteGrup" placeholder="Kalite Grubu Seçiniz" optionLabel="adi" [showClear]="true" (onChange)="anaKaliteDegisti()" [filter]="true"></p-dropdown>
            </li>
            <li>
                <h1>Kalınlık</h1>
            </li>
            <li>
                <p-dropdown [options]="kalinlikGruplari" [(ngModel)]="kalinlikGrup" placeholder="Kalınlık Seçiniz" optionLabel="kalinlik" [showClear]="true" [filter]="true"></p-dropdown>
            </li>

            <li>
                <h1>En (Çap)</h1>
            </li>
            <li>
                <p-inputNumber [(ngModel)]="en" placeholder="En Giriniz" size="8"></p-inputNumber>
            </li>

            <li>
                <h1>Boy</h1>
            </li>
            <li>
                <p-inputNumber [(ngModel)]="boy" placeholder="Boy Giriniz" (onBlur)="stokBul()" size=8></p-inputNumber>
            </li>
            
            <li>
                <h1>Stok Kodları</h1>
            </li>
            <li>
                <p-dropdown [options]="stokGelenler" [(ngModel)]="stokGelen" placeholder="Stok Seçiniz" optionLabel="cariStok.kodu" (onBlur)="stokDegisti()" [showClear]="true"></p-dropdown>
            </li>
            <li>
                <h1>Birimler</h1>
            </li>
            <li>
                <p-dropdown [options]="birimler" [(ngModel)]="birim" placeholder="Birim Seçiniz" [showClear]="true"  optionLabel="birim" ></p-dropdown>
            </li>
            <li>
                <h1>Miktar</h1>
            </li>
            <li>
                <p-inputNumber [(ngModel)]="miktar" placeholder="Adet" (onBlur)="hesapla()"></p-inputNumber>
            </li>
            
            <li>
                <h1>Adet</h1> 
            </li>
            <li>: {{adet}}</li>
            <li>
                <h1>Ağırlık</h1> 
            </li>
            <li>: {{kgMiktar}} Kg.</li>

            <li>
                <h1>Uzunluk</h1>
            </li>
            <li>: {{mtMiktar}} Mt.</li>
            <!--
            <li>
                <h2>Açıklama / Notlar</h2>
                <textarea></textarea>
            </li>
            -->
            <li class="add"><button type="button" label="Teklife Ekle" pButton pRipple (click)="ekle()" class="p-button-info" icon="pi pi-plus"></button></li>
            <li class="back"></li>
        </ul>

        <div class="offers-detail">
            <h3>Teklif Detayları</h3>
             
            <p-table [value]="stokListe" selectionMode="single" [(selection)]="selStok" dataKey="id" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Kod</th>
                        <th>Adı</th>
                        <th>Birim</th>
                        <th>Adet</th>
                        <th>Kg</th>
                        <th>Metre</th>
                        <th>Tarih</th>
                        <th>İşlem</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-stok>
                    <tr [pSelectableRow]="stok">
                        <td>{{stok.cariStok.kodu}}</td>
                        <td>{{stok.cariStok.adi}}</td>
                        <td>{{stok.anaBirim.birim}}</td>
                        <td>{{stok.adet}}</td>
                        <td>{{stok.kgMiktar}}</td>
                        <td>{{stok.mtMiktar}}</td>
                        <td>{{tarih}}</td>
                        <td><button type="button" pButton pRipple icon="pi pi-trash" (click)="stokSil(stok)"></button></td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="offers-explain">
                <p><strong>Açıklama:</strong></p>
                <textarea pInputTextarea [rows]="5" [cols]="30" [(ngModel)]="teklifAciklama"></textarea>
                
                <div class="p-field">
                    <p><strong>Kendi Teklif Numaranız</strong></p>
                    <input id="teklifNo" type="teklifNo" aria-describedby="teklifNo-help" pInputText [(ngModel)]="teklifKodu" />
                    <span class="teklif-info"><i class="pi pi-info-circle"></i> <span class="teklif-not">Teklifi Takip Etmek için kullanacağınız numaradır.</span></span>
                </div>
                
                    <p><strong>Sevkiyat Tipi</strong></p>
                <div class="p-field-radiobutton">
                    <p-radioButton value="false" [(ngModel)]="musteriAlacakMi" inputId="musteriAlacakMi1"></p-radioButton>
                    <label for="musteriAlacakMi1">Mertse Aracı</label>
                    
                    <p-radioButton value="true" [(ngModel)]="musteriAlacakMi" inputId="musteriAlacakMi2"></p-radioButton>
                    <label for="musteriAlacakMi2">Kendi Aracım</label>
                    
                    <!--p-toggleButton id="musteriAlacakMi" aria-describedby="teklifNo-help" onLabel="Aracımız" offLabel="Mertse Aracı" [(ngModel)]="musteriAlacakMi"></p-toggleButton-->
                </div>
            </div>

            <ul class="buttons"> 
                <li>
                    <button type="button" label="Teklif İste" pButton pRipple (click)="gonder()" icon="pi pi-download" class="p-button-success"></button>
                </li>
                <li>
                    <button label="Tekliflerim" pButton pRipple (click)="tekliflereGit()" icon="pi pi-undo" class="p-button-warning" style="color: #fff;"></button>
                    <button label="Bayi Ana Sayfası" pButton pRipple (click)="geridon()" icon="pi pi-undo" class="p-button-warning" style="color: #fff;"></button>
                </li>
            </ul>

        </div>
    </div>
    
</section>