<div [ngClass]="'header-bg'"></div>

<section>
    <div [ngClass]="'user-properties-area'">
		<h1>BAYİ / KULLANICI AYARLARI</h1>
        <ul class="kullanici-bilgi">
            <li><h3><strong>Ünvan:</strong></h3></li>
            <li><h3>{{cariBilgi.firmaAdi}}</h3></li>
            <li><h3><strong>Kullanıcı:</strong></h3></li>
            <li><h3>{{cariBilgi.kullaniciAdi}}</h3></li>
            <li><h3><strong>Adı ve Soyadı:</strong></h3></li>
            <li><h3>{{cariBilgi.adi}} {{cariBilgi.soyadi}}</h3></li>
        </ul>
       
        
        

        <div [ngClass]="'user-properties'">
            <ul>
                <li>
                    <h2>Parola <span>*</span></h2>
                    <input type="password" name="Parola">
                </li>
                <li>
                    <h2>Parola (Tekrar) <span>*</span></h2>
                    <input type="password" name="Parola Tekrar">
                </li>
                <li>
                    <h2>E-Posta <span>*</span></h2>
                    <input type="email" name="E-Posta">
                </li>
                <li>
                    <button>Kaydet</button>
                </li>
            </ul>
        </div>
    </div>
</section>

