<div [ngClass]="'header-bg'"></div>

<section>
    <p-toast position="center"></p-toast> 
    <div [ngClass]="'offer-area'">
        <h1>BAYİ BAĞLANTI TALEPLERİ</h1>
        <app-cari-bilgi></app-cari-bilgi>
        <p-table #dt [value]="talepler" dataKey="id"
[rows]="5" [showCurrentPageReport]="true"  sortMode="multiple"
[paginator]="true" currentPageReportTemplate="{first} ile {last} arası. Toplam: {totalRecords} Kayıt">
    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="cariPersonel">Talep Eden<p-sortIcon field="cariPersonel"></p-sortIcon></th>
            <th pSortableColumn="stokGrubu">Stok Grubu<p-sortIcon field="stokGrubu.adi"></p-sortIcon></th>
            <th pSortableColumn="uretici">Üretici<p-sortIcon field="fiyatIskontoListesi.uretici"></p-sortIcon></th>
            <th pSortableColumn="fiyatListeAdi">Fiyat Tipi<p-sortIcon field="fiyatIskontoListesi.fiyatListeAdi"></p-sortIcon></th>
            <th pSortableColumn="oran">İskonto Oran<p-sortIcon field="fiyatIskontoListesi.oran"></p-sortIcon></th>
            <th pSortableColumn="tarih">Talep Tarihi<p-sortIcon field="talepTarihi"></p-sortIcon></th>
            <th pSortableColumn="starih">Mertse Cevap Tarihi<p-sortIcon field="saticiOnayTarihi"></p-sortIcon></th>
            <th pSortableColumn="scevap">Mertse Cevabı<p-sortIcon field="saticiAciklama"></p-sortIcon></th>
            <th pSortableColumn="mtarih">Cevap Tarihi<p-sortIcon field="musteriOnayTarihi"></p-sortIcon></th>
            <th pSortableColumn="mcevap">Cevap<p-sortIcon field="musteriAciklama"></p-sortIcon></th>
            <th>Bağlantı Numarası</th>
        </tr>
        
    </ng-template>
    <ng-template pTemplate="body" let-talep>
        <tr>
            <td>{{talep.cariPersonel}}</td>
            <td>{{talep.stokGrubu.adi}}</td>
            <td>{{talep.fiyatIskontoListesi.uretici}}</td>
            <td>{{talep.fiyatIskontoListesi.fiyatListeAdi}}</td>
            <td>{{talep.fiyatIskontoListesi.oran}}</td>
            <td>{{talep.talepTarihi| date:'dd/MM/yyyy'}}</td>
            <td>{{talep.saticiOnayTarihi| date:'dd/MM/yyyy'}}</td>
            <td>{{talep.saticiAciklama}}</td>
            <td>{{talep.musteriOnayTarihi| date:'dd/MM/yyyy'}}</td>
            <td>{{talep.musteriAciklama}}</td>
            <td> <span *ngIf="talep.baglantiId!=0">{{talep.baglantiNumarasi}}</span>
            </td>
            <td>
                <span *ngIf="talep.saticiOnay  && !talep.musteriOnay">
                    <button pButton pRipple (click)="dialogAc(talep)"><i class="pi pi-check" title="Cevapla"></i>
                    </button>
                </span>   
            </td>
            
        </tr>
    </ng-template>
</p-table>

    </div>
    <p-dialog header="Cevap" [(visible)]="display">
        <textarea [rows]="5" [cols]="30" [(ngModel)]="talep.musteriAciklama" 
            pInputTextarea autoResize="autoResize"></textarea>
        <button type="button" (click)="kaydet()" pButton autofocus> <i class="pi pi-check" title="Cevapla"></i></button>
    </p-dialog>
</section>       
