export class HizmetDetay{

    id:number;
    hizmetUstGorselPath:string;
	hizmetUstGorselAdi:string;
	hizmetListeGorselPath:string;
	hizmetListeGorselAdi:string;
    hizmetBaslik:string;
    hizmetMetni:string;
    gorselBuyukBase64:string;
    gorselKucukBase64:string;	
    }