import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AnaKaliteGrup } from 'src/app/models/anaKaliteGrup';
import { AnaStokGrup } from 'src/app/models/anastokgrup';
import { CariStok } from 'src/app/models/cariStok';
import { KalinlikGrup } from 'src/app/models/kalinlikgRUP';
import { OlcuBirim } from 'src/app/models/olcuBirim';
import { Stok } from 'src/app/models/stok';
import { StokGrup } from 'src/app/models/stokGrup';
import { TeklifModel } from 'src/app/models/teklifModel';
import { PortalService } from 'src/app/services/portal.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { MessageService } from 'primeng/api';
import { Baglanti } from 'src/app/models/baglanti';
import { KullaniciModel } from 'src/app/models/kullanicimodel';



@Component({
  selector: 'app-teklif-iste',
  templateUrl: './teklif-iste.component.html',
  styleUrls: ['./teklif-iste.component.css'],
  providers: [MessageService]
})
export class TeklifIsteComponent implements OnInit {




  title = 'Mertse | Teklif İste'

  kullaniciModel: KullaniciModel;

  anaStokGruplari: AnaStokGrup[];
  anaStokGrup: AnaStokGrup;

  baglantilar: Baglanti[];
  baglanti: Baglanti;

  stokGruplari: StokGrup[];
  stokGrup: StokGrup;

  anaKaliteGruplari: AnaKaliteGrup[];
  anaKaliteGrup: AnaKaliteGrup;

  kalinlikGruplari: KalinlikGrup[];
  kalinlikGrup: KalinlikGrup;


  stokGelenler: Stok[];
  stokGelen: Stok;
  stokSonuc: Stok;

  stokListe: Array<Stok> = [];
  selStok: Stok;


  en: number;
  boy: number;
  miktar: number;
  adet: number;
  kgMiktar: number;
  mtMiktar: number;
  birimler: Array<OlcuBirim> = [];
  birim: OlcuBirim;
  teklifAciklama: string;
  teklifKodu: string;
  musteriAlacakMi: boolean;

  tarih: Date;
  msg: string;
  teklif: TeklifModel;

  stokModel: Stok;

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    public tokenStorage: TokenStorageService,
    private router: Router,
    private portalSevis: PortalService,
    private messageService: MessageService
  ) {
    this.stokModel = new Stok();
  }

  ngOnInit() {
    if (!this.tokenStorage.isLogin) {
      //login değilse ana sayfaya yönlendir.
      this.router.navigateByUrl('');
    }
    this.kullaniciModel = this.tokenStorage.getUser();
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: '1993 yılında Ekrem Senal tarafından kurularak 110 m² işyeri ile demir çelik sektöründeki yerini almıştır. Firma bugün 15.000 m² kare kapalı 10.000 m² açık alan olmak üzere toplam 25.000 m² alana çıkarak sektördeki ciddiyetini orataya koymuştur.' }
    );
    this.getBaglantilar();
    this.getAnaStokGruplari();
    this.getAnaKaliteGruplari();

  }

  getBaglantilar(): void {

    this.portalSevis.getBaglantilar(this.kullaniciModel.cariId).subscribe(
      data => {
        this.baglantilar = data;
      },
      error => {
        console.log(error);
      });
  }

  baglantiDegisti() {
    if (this.baglanti && this.baglanti.id > 0 && this.baglanti.anaStokId > 0 && this.baglanti.stokId > 0) {
      this.portalSevis.getAnaStokGruplariById(this.baglanti.anaStokId).subscribe(
        data => {
          this.anaStokGruplari = data;
        },
        error => {
          console.log(error);
        });

      this.portalSevis.getStokGruplarById(this.baglanti.stokId).subscribe(
        data => {
          this.stokGruplari = data;
        },
        error => {
          console.log(error);
        });
    } else {
      this.getAnaStokGruplari();
    }

  }

  getAnaStokGruplari(): void {

    this.portalSevis.getAnaStokGruplari().subscribe(
      data => {
        this.anaStokGruplari = data;
      },
      error => {
        console.log(error);
      });
  }

  anaStokDegisti() {
    if(this.baglanti && this.baglanti.id > 0){}else{
    this.portalSevis.getStokGruplari(this.anaStokGrup.id).subscribe(
      data => {
        this.stokGruplari = data;
      },
      error => {
        console.log(error);
      });
    }
  }

  getAnaKaliteGruplari(): void {

    this.portalSevis.getAnaKaliteGruplari().subscribe(
      data => {
        this.anaKaliteGruplari = data;
      },
      error => {
        console.log(error);
      });
  }

  anaKaliteDegisti() {
    this.portalSevis.getKalinlikGruplari(this.anaKaliteGrup.id).subscribe(
      data => {
        this.kalinlikGruplari = data;
      },
      error => {
        console.log(error);
      });

  }

  /*
  stokKontrol(){
  
    this.portalSevis.getStokKontrol(this.stokGelen.kaliteId,this.stokGelen.kalinlikId,this.stokGelen.tabakaEn,this.stokGelen.tabakaBoy).subscribe(
     data => {
       this.stokGelenler = data;
     },
     error => {
       console.log(error);
     });
  }
  */

  stokSil(stok: Stok) {
    this.stokListe.forEach((value, index) => {
      if (value.id == stok.id) this.stokListe.splice(index, 1);
    });
  }
  stokBul() {
    this.portalSevis.getStokKontrol(this.kalinlikGrup, this.en, this.boy).subscribe(
      data => {
        this.stokGelenler = data;
      },
      error => {
        console.log(error);
      });

  }

  stokDegisti() {

    this.birimler = this.stokGelen.birimler;
    this.birim = null;

  }

  ekle() {

    this.stokGelen.adet = this.adet;
    this.stokGelen.kgMiktar = this.kgMiktar;
    this.stokGelen.mtMiktar = this.mtMiktar;
    this.stokListe.push(this.stokGelen);
    this.adet = 0;
    this.kgMiktar = 0;
    this.mtMiktar = 0;
    this.miktar = null;
    this.stokGelen = new Stok();
    this.anaStokGrup = new AnaStokGrup();
    this.stokGrup = new StokGrup();
    this.stokGelenler = null;
    this.kalinlikGrup = new KalinlikGrup();
    this.anaKaliteGrup = new AnaKaliteGrup();
    this.en = null;
    this.boy = null;
    this.birim = null;
    this.birimler = new Array<OlcuBirim>();

  }


  onRowSelect(event) {

  }

  onRowUnselect(event) {
    this.stokGelen = new Stok();
  }

  hesapla() {

    this.portalSevis.getMiktarHesapla(this.stokGelen, this.miktar, this.birim.birim).subscribe(
      data => {
        this.stokSonuc = data;
        this.adet = this.stokSonuc.adet;
        this.kgMiktar = this.stokSonuc.kgMiktar;
        this.mtMiktar = this.stokSonuc.mtMiktar;

      },
      error => {
        console.log(error);
      });


  }

  gonder(): void {


    this.teklif = new TeklifModel();

    this.teklif.cariModel = this.tokenStorage.getUser();
    this.teklif.stokLar = this.stokListe;
    this.teklif.musteriAlacakMi = this.musteriAlacakMi;
    this.teklif.teklifAciklama = this.teklifAciklama;
    this.teklif.teklifKodu = this.teklifKodu;
    if (this.teklif.stokLar.length == 0) {
      this.messageService.add({ severity: 'warn', summary: 'Dikkat', detail: 'Stok seçmediniz.' });
      return;
    }

    this.portalSevis.gonder(this.teklif).subscribe(
      data => {
        this.teklif = data;
        if (this.teklif.mesaj == "Hata") {
          this.messageService.add({ severity: 'error', summary: 'Hata', detail: 'Hata oluştu.Teklif İsteğiniz kayıt edilemedi.' });
        } else {
          this.stokListe = new Array<Stok>();
          this.messageService.add({ severity: 'success', summary: 'Bilgi', detail: 'Teklif İsteğiniz kayıt edildi.' });
        }
      }
    )


  }

  geridon() {
    this.router.navigateByUrl('/bayi');
  }

  tekliflereGit() {
    this.router.navigateByUrl('/tekliflerim');
  }
  /*
  onChangeEvent(event: any){
    console.log(event.target.value);
  }
  */
}