<table>
    <tr *ngFor="let value of values">

        <td>{{value.hizmetBaslik}}</td>
        <td>{{value.hizmetMetni}}</td>

    </tr>



</table>