import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Haberler } from '../haberler/haberler';
import { HaberlerService } from '../services/haberler.service';
import { GlobalConstants } from 'src/app/common/global-constansts';


@Component({
  selector: 'app-main-news',
  templateUrl: './main-news.component.html',
  styleUrls: ['./main-news.component.css']
})
export class MainNewsComponent implements OnInit {

  haberler:Haberler[];
  title='Mertse'
  currentIndex = -1;
  currentHaberler = {
    "id":"",
    "haberKucukResimPath":"",
    "haberKucukResimAdi":"",
    "haberBuyukResimPath":"",
    "haberBuyukResimAdi":"",
    "haberBaslik":"",
    "haberMetni":"",
    "haberOzeti":"",
    "haberTarih":"",
    "gorselKucukBase64":"",
    "gorselBuyukBase64":"",
    "permalink":""
  }; 

  constructor(
 
    private haberlerServis: HaberlerService,
    private route: ActivatedRoute,
    private router: Router
    ) { 

    }

  ngOnInit(): void {
    this.readHaberler();

  }
  readHaberler(): void {
    this.haberlerServis.readAll()
      .subscribe(
        data => {
          this.haberler = data;
        },
        error => {
        });
  }

}
