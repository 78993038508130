        <ul class="bayi-bilgi">
            <li><h2>{{ 'CARI.FIRMA' | translate }}:</h2> <span>{{cariBilgi.firmaAdi}}</span></li>
            <li><h2>KULLANICI:</h2> <span>{{cariBilgi.adi}} {{cariBilgi.soyadi}}</span></li>
            <li><h2>TESLİMAT ADRESİ:</h2> <address>{{cariBilgi.teslimatAdresi}}</address></li>
        </ul> 
        <!-- <p-table #dt [value]="fiyatIskontolari" rendered="cariBilgi.bayi" dataKey="id"
            [rows]="5" [showCurrentPageReport]="true"  sortMode="multiple"
            [paginator]="true" currentPageReportTemplate="{first} ile {last} arası. Toplam: {totalRecords} Kayıt">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="uretici">Üretici<p-sortIcon field="uretici"></p-sortIcon></th>
                    <th pSortableColumn="stokGrubu">Stok Grubu<p-sortIcon field="stokGrubu"></p-sortIcon></th>
                    <th pSortableColumn="fiyatListeAdi">Fiyat Tipi<p-sortIcon field="fiyatListeAdi"></p-sortIcon></th>
                    <th pSortableColumn="oran">İskonto Oran<p-sortIcon field="oran"></p-sortIcon></th>
                </tr>
                <tr>
                    <th>
                        <p-columnFilter type="text" field="uretici" [matchMode]="'contains'"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="stokGrubu"  [matchMode]="'contains'"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="fiyatListeAdi"  [matchMode]="'contains'"></p-columnFilter>
                    </th>
                    <th>
                    </th>
                </tr>
            
            </ng-template>
            <ng-template pTemplate="body" let-fiyat>
                <tr>
                    <td>{{fiyat.uretici}}</td>
                    <td>{{fiyat.stokGrubu}}</td>
                    <td>{{fiyat.fiyatListeAdi}}</td>
                    <td>{{fiyat.oran}}</td>
                </tr>
            </ng-template>
        </p-table> -->


