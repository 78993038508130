<section id="main-news">
    <div class="inside">
        <H1>BİZDEN HABERLER</H1>
        <ul>
            <li *ngFor="let haber of haberler | orderBy: 'haberTarih': 'desc' | slice:0:3; let i = index" [class.active]="i == currentIndex">
                <figure>
                    <img src="{{'data:image/jpg;base64,' + haber.gorselKucukBase64}}" />
                    <time datetime="{{haber.haberTarih | date: 'dd / MM / yyyy'}}">{{haber.haberTarih | date: 'dd / MM / yyyy'}}</time>
                </figure>
                <div>
                    <H2>{{haber.haberBaslik}}</H2>
                    
                    <p>{{haber.haberOzeti}}</p>
                    
                </div>
                <a [routerLink]="['/haberler/haber-detay', haber.permalink]" href="/haberler/haber-detay/{{haber.permalink}}" title="{{haber.haberBaslik}}"></a>
            </li>

        </ul>
    </div>
</section>