import { Injectable } from '@angular/core';
import { SporKulubu } from '../spor-kulubu/spor-kulubu';
import { HttpClient, HttpParams } from '@angular/common/http'; //Adım 2
import { Observable } from 'rxjs'; //Adım 3
import { map,catchError,tap } from "rxjs/operators"; //Adım 4
import { GlobalConstants } from '../../common/global-constansts';

@Injectable({
  providedIn: 'root'
})
export class SporKulubuService {

  baseURL:string='';

  constructor(private httpClient: HttpClient) {
    this.baseURL=GlobalConstants.apiURL+'/getSporKulubu';
    console.log(GlobalConstants.apiURL);
   }
  
  readAll(): Observable<SporKulubu[]> {
    return this.httpClient.get<SporKulubu[]>(this.baseURL);
  }


}
