import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalConstants } from '../common/global-constansts';
import { AnaKaliteGrup } from '../models/anaKaliteGrup';
import { AnaStokGrup } from '../models/anastokgrup';
import { Baglanti } from '../models/baglanti';
import { BaglantiTalep } from '../models/baglantiTalep';
import { FiyatIskonto } from '../models/fiyatIskonto';
import { KalinlikGrup } from '../models/kalinlikGrup';
import { OlcuBirim } from '../models/olcuBirim';
import { PortalCariModel } from '../models/portalCariModel';
import { ResponseModel } from '../models/responseModel';

import { Stok } from '../models/stok';
import { StokGrup } from '../models/stokGrup';
import { TeklifModel } from '../models/teklifModel';

@Injectable({
  providedIn: 'root'
})
export class PortalService {

  baseURL:string='';
  constructor(private httpClient: HttpClient) {

    this.baseURL=GlobalConstants.apiURL;

  }

  /** Bağlantılar web servis */
  getBaglantilar(id): Observable<Baglanti[]> {
    const options = { params: new HttpParams({fromString: "id="+id}) };
    return this.httpClient.get<Baglanti[]>(this.baseURL+ '/getBaglantilar', options);
  }

  /** Baglanti Id ye göre ana stok grupları web servis */
  getAnaStokGruplariById(id): Observable<AnaStokGrup[]> {

    const options = { params: new HttpParams({fromString: "id="+id}) };
    return this.httpClient.get<AnaStokGrup[]>(this.baseURL+ '/getAnaStokGruplariById',options); 
  }

  /** Baglanti Id ye göre alt stok grupları web servis */
  getStokGruplarById(id): Observable<StokGrup[]> {

    const options = { params: new HttpParams({fromString: "id="+id}) };
    return this.httpClient.get<StokGrup[]>(this.baseURL+ '/getStokGruplarById',options); 
  }

  /** Ana Stok Grupları web servis */
  getAnaStokGruplari(): Observable<AnaStokGrup[]> {
    return this.httpClient.get<AnaStokGrup[]>(this.baseURL+ '/getAnaStokGruplari');
  }

  /** Ana Stok Id ye göre alt stok grupları web servis */
  getStokGruplari(id): Observable<StokGrup[]> {

    const options = { params: new HttpParams({fromString: "id="+id}) };
    return this.httpClient.get<StokGrup[]>(this.baseURL+ '/getStokGruplari',options); 
  }
  
    /** Ana Kalite Grupları web servis */
    getAnaKaliteGruplari(): Observable<AnaKaliteGrup[]> {
      return this.httpClient.get<AnaKaliteGrup[]>(this.baseURL+ '/getKalite');
    }

    /** Kalınlık Grupları web servis */
    getKalinlikGruplari(id): Observable<KalinlikGrup[]> {

      const options = { params: new HttpParams({fromString: "id="+id}) };
      return this.httpClient.get<KalinlikGrup[]>(this.baseURL+ '/getKalinlik',options); 
    }

    getStokKontrol(kalinlik:KalinlikGrup,tabakaEn:number,tabakaBoy:number): Observable<Stok[]> {

      const options = { params: new HttpParams({fromString:
        "kalite_id="+kalinlik.kaliteId+"&kalinlik_id="+kalinlik.id+"&en="+tabakaEn+"&boy="+tabakaBoy}) };
        return this.httpClient.get<Stok[]>(this.baseURL+ '/getUrunlerStok',options); 
    }

    getMiktarHesapla(stok:Stok,miktar:number,birim:string): Observable<Stok> {

      const options = { params: new HttpParams({fromString:
        "stokId="+stok.id+"&miktar="+miktar+"&birim="+birim}) };
        return this.httpClient.get<Stok>(this.baseURL+ '/getMiktarHesapla',options); 
    }


    gonder(stok:TeklifModel): Observable<any> {
      return this.httpClient.post(this.baseURL+'/webTeklif',stok);
    }

    getTeklifler(cariId:number): Observable<TeklifModel[]> {
      const options = { 
        params: new HttpParams({fromString:"cariId="+cariId}) 
      };
        return this.httpClient.get<TeklifModel[]>(this.baseURL+ '/tekliflerim',options); 
    }

    sipariseDonustur(teklifId:number): Observable<ResponseModel> {
      return this.httpClient.post<ResponseModel>(this.baseURL+'/sipariseDonustur',teklifId);
    }

    getSiparisler(cariId:number): Observable<TeklifModel[]> {
      const options = { 
        params: new HttpParams({fromString:"cariId="+cariId}) 
      };
        return this.httpClient.get<TeklifModel[]>(this.baseURL+ '/siparislerim',options); 
    }

    getCariHareketleri(cariId:number): Observable<PortalCariModel> {
      const options = { 
        params: new HttpParams({fromString:"cariId="+cariId}) 
      };
        return this.httpClient.get<PortalCariModel>(this.baseURL+ '/cariHareketleri',options); 
    }

    getIskontoListesi(cariId:number): Observable<FiyatIskonto[]> {
      const options = { 
        params: new HttpParams({fromString:"cariId="+cariId}) 
      };
        return this.httpClient.get<FiyatIskonto[]>(this.baseURL+ '/fiyatIskontolari',options); 
    }

    baglantiTalepOlustur(talep:BaglantiTalep):Observable<ResponseModel>{ 
      return this.httpClient.post<ResponseModel>(this.baseURL+"/baglantiTalep",talep);
    }

    getBirimler(): Observable<OlcuBirim[]> {
        return this.httpClient.get<OlcuBirim[]>(this.baseURL+ '/olcuBirimleri'); 
    }

    getBaglantiTaleplerim(cariId:number):Observable<BaglantiTalep[]>{
      const options = { 
        params: new HttpParams({fromString:"cariId="+cariId}) 
      };
        return this.httpClient.get<BaglantiTalep[]>(this.baseURL+ '/taleplerim',options); 
    }
    
    baglantiTalepOnay(talep:BaglantiTalep):Observable<ResponseModel>{ 
      return this.httpClient.post<ResponseModel>(this.baseURL+"/baglantiTalepOnay",talep);
    }

}