import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-baglanti-siparis-component',
  templateUrl: './baglanti-siparis-component.component.html',
  styleUrls: ['./baglanti-siparis-component.component.css']
})
export class BaglantiSiparisComponentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
