import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { HizmetDetayService } from 'src/app/components/services/hizmet-detay.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { HizmetDetay } from './hizmetDetay';
import { GlobalConstants } from 'src/app/common/global-constansts';



@Component({
  selector: 'app-hizmet-detay',
  templateUrl: './hizmet-detay.component.html',
  styleUrls: ['./hizmet-detay.component.css']
})
export class HizmetDetayComponent implements OnInit {

  title='Mertse | Hizmetlerimiz Detay'
  currentIndex = -1;
  currentHizmetlerimizDetay:HizmetDetay = new HizmetDetay(); 

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private hizmetlerimizDetayServis: HizmetDetayService,
    private route: ActivatedRoute,
    private router: Router
  ) { 

  }

  ngOnInit(): void {
    this.readHizmetDetay();
  }

  readHizmetDetay(): void {

     const pln = this.route.snapshot.paramMap.get('permalink');

    this.hizmetlerimizDetayServis.getHizmet(pln)
      .subscribe(
        data => {
          this.currentHizmetlerimizDetay = data;
        },
        error => {
        });
  }

}
