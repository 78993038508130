import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { finalize } from 'rxjs/operators';
import { KullaniciModel } from 'src/app/models/kullanicimodel';
import { PortalCariModel } from 'src/app/models/portalCariModel';
import { PortalService } from 'src/app/services/portal.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-hesap-hareketleri',
  templateUrl: './hesap-hareketleri.component.html',
  styleUrls: ['./hesap-hareketleri.component.css'],
  providers: [MessageService]
})
export class HesapHareketleriComponent implements OnInit {

  title='Mertse | Bayi Hesap Hareketleri'

  cari:PortalCariModel;
  kullanici:KullaniciModel;
  loading = false;

  constructor(
    private titleService: Title,
    private portalSevis:PortalService,
    public tokenStorage: TokenStorageService,
    private metaTagService: Meta,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.cari=new PortalCariModel();
    this.kullanici=this.tokenStorage.getUser();
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Bayi Ödeme, Borç ve Alacak Bilgileri.' }
    );
    this.loading = true;
    this.getCariBilgi();
  }

  getCariBilgi(){
    this.portalSevis.getCariHareketleri(this.kullanici.cariId).pipe(finalize(() => this.loading = false)
    ).subscribe(
      data => {
        this.cari=data;
      },
      error => {
        this.messageService.add({severity:'error', summary:'Hata', detail:error});
      });
  }

}