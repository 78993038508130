import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KullaniciModel } from 'src/app/models/kullanicimodel';
import { LoginService } from 'src/app/services/login.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import {Md5} from 'ts-md5/dist/md5';
import {NgbToastModule} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-bayilb',
  templateUrl: './bayilb.component.html',
  styleUrls: ['./bayilb.component.css']
})

export class BayilbComponent implements OnInit {

  msg :string ="";
  kullanici:KullaniciModel;

  constructor(
    private loginServis : LoginService,
    private route: ActivatedRoute,
    private router: Router,
    public tokenStorage: TokenStorageService
  ){
    this.kullanici=new KullaniciModel();

  }

  ngOnInit(): void {
    this.kullanici=new KullaniciModel();
  }


  login(): void {
      
    
      if(this.kullanici.cariId==null){
        //mesaj verilecek.
        this.msg="Bayi Kodu Giriniz!"; 
        return;
      }

      if(this.kullanici.kullaniciAdi==''){
         //mesaj verilecek.
         this.msg="Kullanıcı Giriniz!";
         return;
 
      }

      if(this.kullanici.sifre==''){
        //mesaj verilecek.
        this.msg="Şifre Giriniz!";
        return;
      }

      this.kullanici.sifre=Md5.hashStr(this.kullanici.sifre);

      this.loginServis.login(this.kullanici).subscribe(
        kulModel=>{
          if(kulModel!=null){
            this.kullanici=kulModel;
            this.tokenStorage.saveToken(this.kullanici.session);
            this.tokenStorage.saveUser(this.kullanici);
            this.router.navigateByUrl('/bayi');
          }else{
            this.msg="Kullanıcı Adı yada Şifre Yanlış!";
            this.tokenStorage.signOut();
          }
          //yönlendirme olacak.
        },
        error => {
          this.kullanici=new KullaniciModel();
          this.msg="Hata Oluştu!";
          console.log(error);
        }
      )

  }

  
}
