<header>
	<div [ngClass]="'header-top'">
		<div [ngClass]="'inside'">
			<div [ngClass]="'top-left'">
				<div [ngClass]="'top-dealer'">
					<a class="iframe-lightbox-link" [routerLink]="['/bayilb']" routerLinkActive="router-link-active"  *ngIf="!tokenStorage.isLogin" 
					 title="Bayi Girişi"><img src="./assets/images/user.svg" alt="Bayi Girişi">Bayi Girişi</a>

					
					 <a class="iframe-lightbox-link" (click)="singout()" *ngIf="tokenStorage.isLogin" title="Bayi Çıkış">
						<img src="./assets/images/user.svg" alt="Bayi Çıkış">Çıkış
					</a>
				</div>
			</div>
			<div [ngClass]="'top-right'">
				<div [ngClass]="'social'">
					<a href="https://www.facebook.com/mertsemetal/" target="_blank" title="Mertse Facebook"><i [ngClass]="'fthsc-facebook'"></i></a>
					<a href="https://twitter.com/mertsemetal" target="_blank" title="Mertse Twitter"><i [ngClass]="'fthsc-twitter'"></i></a>
					<a href="https://youtube.com/channel/UCjeLxG9nJoHAqPAHmMuws7g" target="_blank" title="Mertse Youtube"><i [ngClass]="'fthsc-youtube'"></i></a>
					<a href="https://tr.linkedin.com/company/mertse" target="_blank"  title="Mertse LinkedIn"><i [ngClass]="'fthsc-linkedin'"></i></a>
				</div>
			</div>
		</div>
	</div>

	<div [ngClass]="'header-bottom'">
		<figure><a [routerLink]="['/']" href="/" title="Mertse Sac Metal Ana Sayfa"><img src="./assets/images/logo.png" alt="Mertse Logo"></a></figure>
		<nav>
			<ul>
				<li><a [routerLink]="['/']" href="/" title="Mertse Ana Sayfa">Ana Sayfa</a></li>
				<li><a [routerLink]="['/kurumsal']" href="/kurumsal" title="Kurumsal">Kurumsal</a>
					<ul>
						<li><a [routerLink]="['/kurumsal/hakkimizda']" href="/kurumsal/hakkimizda" title="Hakkımızda">Hakkımızda</a></li>
						<li><a [routerLink]="['/kurumsal/misyon-vizyon']" href="/kurumsal/misyon-vizyon" title="Misyon & Vizyon">Misyon & Vizyon</a></li>
						<li><a [routerLink]="['/kurumsal/organizasyon-semasi']" href="/kurumsal/organizasyon-semasi" title="Organizasyon Şeması">Organizasyon Şeması</a></li>
						<li *ngIf="tokenStorage.isLogin"><a [routerLink]="['/kurumsal/makine-parkurumuz']" href="/kurumsal/makine-parkurumuz" title="Makine Parkurumuz">Makine Parkurumuz</a></li>
					</ul>
				</li>
				<li><a [routerLink]="['/hizmetlerimiz']" href="/hizmetlerimiz" title="Hizmetlerimiz">Hizmetlerimiz</a></li>
				<li><a [routerLink]="['/urunler']" href="/urunler" title="Ürünler">Ürünler</a></li>
				<li><a [routerLink]="['/haberler']" href="/haberler" title="Haberler">Haberler</a></li>
				<li *ngIf="tokenStorage.isLogin"><a [routerLink]="['/bayi']" href="/bayi" title="Bayi Portalı">Bayi Portalı</a></li> 
				<li><a [routerLink]="['/contact']" href="/contact" title="İletişim">İletişim</a></li>
				<!--li><a [routerLink]="['/spor-kulubu']" href="/spor-kulubu" title="Spor Kulübü">Spor Kulübümüz</a></li-->
			</ul>
		</nav>
	</div>
</header>
