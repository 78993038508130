<section id="content">
    <figure>
        <div [ngClass]="'breadcrumb'">
            <h1>Sac Ağırlık Hesaplama</h1>
            <ul id="breadcrumb" itemscope itemtype="http://schema.org/BreadcrumbList">
				<li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item"  routerLink="/" title="Ana Sayfa"><span  itemprop="name">Ana Sayfa</span></a><meta itemprop="position" content="1" /></li>
				<li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item"  routerLink="/kurumsal" title="Kurumsal"><span  itemprop="name">Kurumsal</span></a><meta itemprop="position" content="2" /></li>
				<li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><meta itemprop="position" content="Sac Ağırlık Hesaplama" />Sac Ağırlık Hesaplama<meta itemprop="position" content="3" /></li>
			</ul>
        </div>
    </figure>
        <div [ngClass]="'offer-area'">
            <ul>
               
               
    
                <li>
                    <h1>EN</h1>
                </li>
                <li>
                    <p-inputNumber [(ngModel)]="en" placeholder="En Giriniz" size="8"></p-inputNumber>
                </li>
    
                <li>
                    <h1>Boy</h1>
                </li>
                <li>
                    <p-inputNumber [(ngModel)]="boy" placeholder="Boy Giriniz" size=8></p-inputNumber>
                </li>
                
           
                <li>
                    <h1>Kalınlık</h1>
                </li>
                <li>
                    <p-inputNumber [(ngModel)]="kalinlik" placeholder="Kalınlık" size=8></p-inputNumber>
                </li>

                <li>
                    <h1>Ağırlık</h1>
                </li>
                <li>
                    {{ agirlik | number:'1.0-3'}} Kg.
                </li>

                <li>
                    <button (click)="hesapla()">HESAPLA</button>
                </li>
                

          
        
            </ul> 

        </div>
</section>
