<div [ngClass]="'header-bg'"></div>

<section>
    <div [ngClass]="'my-offers-area dealer-login'">
		<H1>BAYİ / MÜŞTERİ GİRİŞİ</H1>
		<div class="inside" *ngIf="!tokenStorage.isLogin">
				<div *ngIf="msg" class="error-msg">{{msg}}</div>
				<ul>	
					<li>
						<img src="/assets/images/dealer-code.png" title="Bayi Kodu">
					</li>
					<li>
						<p-inputNumber class="userId" [(ngModel)]="kullanici.cariId"
						id="bayiKodu"
						name ="bayiKodu"
						placeholder="Bayi Kodu"></p-inputNumber>
						
					</li>
					<li><img src="/assets/images/user.png" title="Kullanıcı Adı"></li>
					<li>
						<input
							placeholder="Kullanıcı Adı"
							type="text"
							class="form-control"
							id="username"
							name="username"
							[(ngModel)]="kullanici.kullaniciAdi"
							/>
						</li>
						<li><img src="/assets/images/password.png" title="Şifre"></li>
						<li>
							<input placeholder="Şifre" type="password" id="passwrd" name="passwrd" 
							[(ngModel)]="kullanici.sifre"/>
						</li>

				</ul>
				<button (click)="login()"  class="login">
					Giriş Yap
				</button>
				
				
			<div class="dealer-links">
				<!--<a href="/bayi" title="Bayi / Müşteri Ol" target="_blank">Bayi / Müşteri Ol</a> -->
				<a href="" title="Şifremi Unuttum!">Şifremi Unuttum!</a>
				<br>
				<a href="" title="Müşteri / Bayilik Başvurusu">Müşteri / Bayilik Başvurusu</a>
			</div>
		</div>
	</div>
</section>