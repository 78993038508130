import { CariHareketModel } from "./cariHareketModel";

export class PortalCariModel{
    id:number;
	cariKodu:string;
	unvan:string;
	tcNo:string;
	vergiNo:string;
	vergiDaire:string;
	sevkAdresi:string;
	faturaAdresi:string;
	temsilci:string;
	eposta:string;
	cepTel:string;
	isTel:string;
	odemeTipi:string;
	vadeGun:string;
	acikHesapLimit:number;
	kendiCekLimiti:number;
	musteriCekLimiti:number;
	bakiye:number;
	kendiCekRisk:number;
	musteriCekRisk:number;
	geciken:number;
	acikHesapBosluk:number;
	kendiCekBosluk:number;
	musteriCekBosluk:number;
	ortalamaVade:Date;	
	hareketler:CariHareketModel[];
}