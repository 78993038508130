import { Component, OnInit } from '@angular/core';
import { KurumsalService } from 'src/app/components/services/kurumsal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { Kurumsal } from './kurumsal';
import { GlobalConstants } from 'src/app/common/global-constansts';

@Component({
  selector: 'app-kurumsal',
  templateUrl: './kurumsal.component.html',
  styleUrls: ['./kurumsal.component.css'],
})
export class KurumsalComponent implements OnInit {
  title='Mertse | Kurumsal'
  currentKurumsal = {
    "id":"",
    "kurumsalGorselAdi":"",
    "kurumsalIcerikTitle":"",
    "kurumsalIcerikMetaDesc":"",
    "kurumsalIcerikBaslik":"",
    "kurumsalIcerikMetni":"",
    "gorselBase64":""
  };

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private kurumsalServis: KurumsalService,
    private route: ActivatedRoute,
    private router: Router,
  ) { 

  }

  ngOnInit() {

    this.getKurumsal(0);

    this.metaTagService.updateTag(
      {
        name: 'description',
        content: this.currentKurumsal.kurumsalIcerikMetaDesc
      }
    );
  }

getKurumsal(id){
  this.kurumsalServis.read(id)
    .subscribe(
      kurumsal => {
        this.currentKurumsal = kurumsal;
        this.titleService.setTitle(this.currentKurumsal.kurumsalIcerikTitle);
      },
      error => {
      });
}

}
