import { Component, OnInit } from '@angular/core';
import { KurumsalService } from 'src/app/components/services/kurumsal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { Kurumsal } from '../kurumsal/kurumsal';
import { GlobalConstants } from 'src/app/common/global-constansts';
@Component({
  selector: 'app-hakkimizda',
  templateUrl: './hakkimizda.component.html',
  styleUrls: ['./hakkimizda.component.css']
})
export class HakkimizdaComponent implements OnInit {
  title='Mertse | Hakkımızda'

  currentKurumsal = {
  "id":"0",
  "kurumsalGorselAdi":"",
  "kurumsalIcerikTitle":"",
  "kurumsalIcerikMetaDesc":"",
  "kurumsalIcerikBaslik":"",
  "kurumsalIcerikMetni":"",
  "gorselBase64":""
};
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private kurumsalServis: KurumsalService,
    private route: ActivatedRoute,
    private router: Router,
  ) { 

  }

  ngOnInit() {
    this.getKurumsal(2);
    this.metaTagService.updateTag(
      {
        name: 'description',
        content: this.currentKurumsal.kurumsalIcerikMetaDesc
      }
    );
  }

  getKurumsal(id){
    this.kurumsalServis.read(id)
      .subscribe(
        kurumsal => {
          this.currentKurumsal = kurumsal;
          this.titleService.setTitle(this.currentKurumsal.kurumsalIcerikTitle);
        },
        error => {
        });
  }
  
  

}