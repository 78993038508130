import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Hesap } from 'src/app/models/hesap';



@Component({
  selector: 'app-hesaplama-sac',
  templateUrl: './hesaplama-sac.component.html',
  styleUrls: ['./hesaplama-sac.component.css']
})
export class HesaplamaSacComponent implements OnInit {

  title='Mertse | Sac Ağırlık Hesaplama'


  en:number;
  boy:number;
  kalinlik:number;
  agirlik:number;
  

  public hesapModel : Hesap={
    "tabakaEn" : 0,
    "tabakaBoy": 0,
    "kalinlik": 0,
    "agirlik":0,
    };
  
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private router: Router,
  ) { }

  ngOnInit(): void {

    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: '1993 yılında Ekrem Senal tarafından kurularak 110 m² işyeri ile demir çelik sektöründeki yerini almıştır. Firma bugün 15.000 m² kare kapalı 10.000 m² açık alan olmak üzere toplam 25.000 m² alana çıkarak sektördeki ciddiyetini orataya koymuştur.' }
    );
  }

  hesapla(){

    this.agirlik = this.en * this.boy * this.kalinlik * 7.86 / 1000000; 
    
  }

}

