import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { KullaniciModel } from 'src/app/models/kullanicimodel';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-bayi',
  templateUrl: './bayi.component.html',
  styleUrls: ['./bayi.component.css']
})
export class BayiComponent implements OnInit {

  bayiButonlar = [];
  cari:KullaniciModel;

  
  title='Mertse | Bayi Paneli'

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    public tokenStorage: TokenStorageService,
    private router: Router,
  ) { }

  ngOnInit() {
    if(!this.tokenStorage.isLogin){
        //login değilse ana sayfaya yönlendir.
        this.router.navigateByUrl('');

    }
    this.cari=this.tokenStorage.getUser();
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: '1993 yılında Ekrem Senal tarafından kurularak 110 m² işyeri ile demir çelik sektöründeki yerini almıştır. Firma bugün 15.000 m² kare kapalı 10.000 m² açık alan olmak üzere toplam 25.000 m² alana çıkarak sektördeki ciddiyetini orataya koymuştur.' }
    );
  }

}
