import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { KurumsalComponent } from './components/kurumsal/kurumsal.component';
import { ContactComponent } from './components/contact/contact.component';
import { SliderComponent } from './components/slider/slider.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PhoneMaskDirective } from './components/contact/phone-mask.directives';
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { MainProductsComponent } from './components/main-products/main-products.component';
import { HomeComponent } from './components/home/home.component';
import { MainProductionsComponent } from './components/main-productions/main-productions.component';
import { MainNewsComponent } from './components/main-news/main-news.component';
import { MainContactComponent } from './components/main-contact/main-contact.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContentModule } from './content/content.module';
import { BayiComponent } from './components/bayi/bayi.component';
import { BayilbComponent } from './components/bayilb/bayilb.component';
import { MisyonVizyonComponent } from './components/misyon-vizyon/misyon-vizyon.component';
import { OrganizasyonSemasiComponent } from './components/organizasyon-semasi/organizasyon-semasi.component';
import { MakineParkuruComponent } from './components/makine-parkuru/makine-parkuru.component';
import { HizmetlerimizComponent } from './components/hizmetlerimiz/hizmetlerimiz.component';
import { UrunlerComponent } from './components/urunler/urunler.component';

import {NgbModule, NgbToastModule} from '@ng-bootstrap/ng-bootstrap';
import {ButtonModule} from 'primeng/button';
import {RadioButtonModule} from 'primeng/radiobutton';
import { RippleModule } from "primeng/ripple";
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { NavDrawerComponent } from './components/nav-drawer/nav-drawer.component';
import { GizlilikVeKisiselVerilerinKorunmasiPolitikasiComponent } from './gizlilik-ve-kisisel-verilerin-korunmasi-politikasi/gizlilik-ve-kisisel-verilerin-korunmasi-politikasi.component';
import { KullanimKosullariComponent } from './kullanim-kosullari/kullanim-kosullari.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrunDetayComponent } from './components/urun-detay/urun-detay.component';
import { ValueComponent } from './components/value/value.component';
import { HakkimizdaComponent } from './components/hakkimizda/hakkimizda.component';
import { HizmetDetayComponent } from './components/hizmet-detay/hizmet-detay.component';
import { TeklifIsteComponent } from './components/teklif-iste/teklif-iste.component';
import { TekliflerimComponent } from './components/tekliflerim/tekliflerim.component';
import { SiparislerimComponent } from './components/siparislerim/siparislerim.component';
import { KullaniciAyarlariComponent } from './components/kullanici-ayarlari/kullanici-ayarlari.component';
import { HesapHareketleriComponent } from './components/hesap-hareketleri/hesap-hareketleri.component';
import { SporKulubuComponent } from './components/spor-kulubu/spor-kulubu.component';
import { SporKulubuDetayComponent } from './components/spor-kulubu-detay/spor-kulubu-detay.component';
import { DropdownModule } from 'primeng/dropdown';
import { CariBilgiComponent } from './components/cari-bilgi/cari-bilgi.component';
import { HaberlerComponent } from './components/haberler/haberler.component';
import { HaberDetayComponent } from './components/haber-detay/haber-detay.component'; 
import {InputNumberModule} from 'primeng/inputnumber';
import {CarouselModule} from 'primeng/carousel';
import {TableModule} from 'primeng/table';
import {CalendarModule} from 'primeng/calendar';
import { OrderByPipe } from './orderby.pipe';
import {ToastModule} from 'primeng/toast';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputTextModule} from 'primeng/inputtext';
import {SelectButtonModule} from 'primeng/selectbutton';
import {ToggleButtonModule} from 'primeng/togglebutton';
import { HesaplamaSacComponent } from './components/hesaplama-sac/hesaplama-sac.component';
import { BaglantiTalepComponent } from './components/baglanti-talep/baglanti-talep.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { BaglantiTaleplerimComponent } from './components/baglanti-taleplerim/baglanti-taleplerim.component';
import {DialogModule} from 'primeng/dialog';
import { BaglantiSiparisComponentComponent } from './components/baglanti-siparis-component/baglanti-siparis-component.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    KurumsalComponent,
    HakkimizdaComponent,
    ContactComponent,
    PhoneMaskDirective,
    SliderComponent,
    MainProductsComponent,
    HomeComponent,
    MainProductionsComponent,
    MainNewsComponent,
    MainContactComponent,
    FooterComponent,
    BayiComponent,
    BayilbComponent,
    MisyonVizyonComponent,
    OrganizasyonSemasiComponent,
    MakineParkuruComponent,
    HizmetlerimizComponent,
    UrunlerComponent,
    NavBarComponent,
    NavDrawerComponent,
    GizlilikVeKisiselVerilerinKorunmasiPolitikasiComponent,
    KullanimKosullariComponent,
    UrunDetayComponent,
    ValueComponent,
    HizmetDetayComponent,
    TeklifIsteComponent,
    TekliflerimComponent,
    SiparislerimComponent,
    KullaniciAyarlariComponent,
    HesapHareketleriComponent,
    SporKulubuComponent,
    SporKulubuDetayComponent,
    CariBilgiComponent,
    HaberlerComponent,
    HaberDetayComponent,
    OrderByPipe,
    HesaplamaSacComponent,
    BaglantiTalepComponent,
    BaglantiTaleplerimComponent,
    BaglantiSiparisComponentComponent
    ],
  imports: [
    BrowserModule,
    NgbModule,
    ButtonModule,
    RadioButtonModule,
    RippleModule,
    AppRoutingModule,
    ContentModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    DropdownModule,
    InputNumberModule,
    CarouselModule,
    NgbToastModule,
    TableModule,
    CalendarModule,
    ToastModule,
    InputTextareaModule,
    InputTextModule,
    SelectButtonModule,
    ToggleButtonModule,
    DialogModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  
}
