<section id="content">
    <figure [ngStyle]="{ 'background-image': 'url(data:image/jpeg;base64,'+currentHizmetlerimizDetay.gorselBuyukBase64+')' }">
        <div [ngClass]="'breadcrumb'">
            <h1>{{currentHizmetlerimizDetay.hizmetBaslik}}</h1>
            <ul id="breadcrumb" itemscope itemtype="http://schema.org/BreadcrumbList">
                <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item" routerLink="/" title="Ana Sayfa"><span  itemprop="name">Ana Sayfa</span></a>
                    <meta itemprop="position" content="1" />
                </li>
                <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item" routerLink="/hizmetlerimiz" title="Hizmetlerimiz"><span  itemprop="name">Hizmetlerimiz</span></a>
                    <meta itemprop="position" content="2" />
                </li>
                <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
                    <meta itemprop="position" content="3" />{{currentHizmetlerimizDetay.hizmetBaslik}}
                    </li>
            </ul>
        </div>
    </figure>
    <div id="article-text" [innerHTML]="currentHizmetlerimizDetay.hizmetMetni" class="content">
        {{currentHizmetlerimizDetay.hizmetMetni}}
    </div>
</section>