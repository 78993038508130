import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { KullaniciModel } from 'src/app/models/kullanicimodel';
import { TeklifModel } from 'src/app/models/teklifModel';
import { PortalService } from 'src/app/services/portal.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { Router } from '@angular/router';
import { ResponseTypes } from 'src/app/enums/response-types.enum';


@Component({
  selector: 'app-tekliflerim',
  templateUrl: './tekliflerim.component.html',
  styleUrls: ['./tekliflerim.component.css'],
  providers: [MessageService]
})
export class TekliflerimComponent implements OnInit {

  title='Mertse | Tekliflerim'

  tekliflerim:Array<TeklifModel>;
  kullanici:KullaniciModel;

  constructor(
    private titleService: Title,
    private portalSevis:PortalService,
    public tokenStorage: TokenStorageService,
    private router: Router,
    private metaTagService: Meta,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: '1993 yılında Ekrem Senal tarafından kurularak 110 m² işyeri ile demir çelik sektöründeki yerini almıştır. Firma bugün 15.000 m² kare kapalı 10.000 m² açık alan olmak üzere toplam 25.000 m² alana çıkarak sektördeki ciddiyetini orataya koymuştur.' }
    );
    this.getTekliflerim();
    if(!this.tokenStorage.isLogin){
      //login değilse ana sayfaya yönlendir.
      this.router.navigateByUrl('');
    }
  }

  getTekliflerim(){

    this.kullanici= this.tokenStorage.getUser();
    this.portalSevis.getTeklifler(this.kullanici.id).subscribe(
      data => {
        this.tekliflerim=data;
      },
      error => {
        this.messageService.add({severity:'error', summary:'Hata', detail:error});
   
        console.log(error);
      });

  }

  sipariseDonustur(teklifId:number){
    this.portalSevis.sipariseDonustur(teklifId).subscribe(
      data => {
        if(data.respType=='OK'){
          this.messageService.add({severity:'info', summary:'Bilgi', detail:'Sipariş Onaylandı!'});
          this.getTekliflerim();
        }else{
          this.messageService.add({severity:'error', summary:'Hata', detail:data.respMessage});
        }
      },
      error => {
        this.messageService.add({severity:'error', summary:'Hata', detail:error.error.text});
      });

  }
  geridon(){
    this.router.navigateByUrl('/bayi');
  }

}