import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BaglantiTalep } from 'src/app/models/baglantiTalep';
import { KullaniciModel } from 'src/app/models/kullanicimodel';
import { PortalService } from 'src/app/services/portal.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-baglanti-taleplerim',
  templateUrl: './baglanti-taleplerim.component.html',
  styleUrls: ['./baglanti-taleplerim.component.css'],
  providers: [MessageService]
})
export class BaglantiTaleplerimComponent implements OnInit {

  title='Mertse | Bağlantı Taleplerim';
  display:boolean;
  kullanici:KullaniciModel;
  talepler:Array<BaglantiTalep>;
  talep:BaglantiTalep;

  constructor(private titleService: Title,
    private metaTagService: Meta,
    public tokenStorage: TokenStorageService,
    private router: Router,
    private portalSevis:PortalService,
    private messageService: MessageService) { }

  ngOnInit(): void {

    if(!this.tokenStorage.isLogin){
      //login değilse ana sayfaya yönlendir.
      this.router.navigateByUrl('');
    }
    this.kullanici=this.tokenStorage.getUser();
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: '1993 yılında Ekrem Senal tarafından kurularak 110 m² işyeri ile demir çelik sektöründeki yerini almıştır. Firma bugün 15.000 m² kare kapalı 10.000 m² açık alan olmak üzere toplam 25.000 m² alana çıkarak sektördeki ciddiyetini orataya koymuştur.' }
    );
    this.talep=new BaglantiTalep();
    this.getTalepler();

  }

  getTalepler(){
    this.portalSevis.getBaglantiTaleplerim(this.kullanici.cariId).subscribe(
      data=>{
          this.talepler=data;
      },
      error=>{
        this.messageService.add({severity:'error', summary:'Hata', detail:'Hata oluştu.'+error.error.text});
      }
    );
  }

  dialogAc(talep:BaglantiTalep){
    this.talep=talep;
    this.display=true;
  }

  kaydet(){
    if(this.talep.musteriAciklama==null){
      this.messageService.add({severity:'warn', summary:'Dikkat', detail:'Cevap Giriniz!'});
      return;
    }
    this.portalSevis.baglantiTalepOnay(this.talep).subscribe(
      data=>{
        if(data.respType=='OK'){
          this.messageService.add({severity:'info', summary:'Bilgi', detail:'Cevabınız iletildi. En kısa sürede bağlantınız oluşturulacaktır.'});
          this.getTalepler();
          this.display=false;
        }else{
          this.messageService.add({severity:'error', summary:'Hata', detail:'Hata oluştu.'+data.respMessage});
        }
      },
      error=>{
        this.messageService.add({severity:'error', summary:'Hata', detail:'Hata oluştu.'+error.error.text});
      }
    );
    
  }

}
