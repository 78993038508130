
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  
  title='Mertse | İletişim'

  mailForm: FormGroup;
  

  submitted = false;

  constructor(private http: HttpClient, private fb: FormBuilder, private titleService: Title, private metaTagService: Meta){
    this.createForm();
  }

  createForm(){
    this.mailForm = this.fb.group({ 
      phone:['',[Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/),Validators.required]],
    });
  }
  
  //Mail user form actions
  get f() { return this.mailForm.controls; }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.mailForm.invalid) {
        return;
    }
    //True if all the fields are filled
    if(this.submitted)
    {
      
      // Initialize Params Object
      var myFormData = new FormData();
    
    // Begin assigning parameters
    
        myFormData.append('myUsername', this.mailForm.value.adiSoyadi);
        myFormData.append('myEmail', this.mailForm.value.eposta);
        myFormData.append('myCompany', this.mailForm.value.firma);
        myFormData.append('myPhone', this.mailForm.value.phone);
        myFormData.append('textquery', this.mailForm.value.mesaj);
    
      //post request
      return this.http.post('http://localhost:4200/laravel8/public/api/send/email'
      , myFormData).subscribe((res: Response) => {
        
    });
    }
  
  }
  ngOnInit() {
      //Mail User form validations
      this.mailForm = this.fb.group({
      eposta: ['', [Validators.required, Validators.email]],
      adiSoyadi: ['', [Validators.required]],
      telefon: ['', [Validators.required]],
      firma: ['', [Validators.required]],
      mesaj:['', [Validators.required]]
    });

    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Metal ile ilgili ihtiyaçlarınız için bizimle iletişime geçebilirsiniz.' }
    );
  }
}
