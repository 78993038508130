import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';



@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  
  public isLogin:boolean=false;

  constructor() {
   
    this.isLogin== this.getUser()!=null;

   }

  signOut(): void {
    window.sessionStorage.clear();
    this.isLogin=false;
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
    this.isLogin=true;
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      this.isLogin=true;
      return JSON.parse(user);
    }else{
      this.isLogin=false;
    }

    return null;
  }
}