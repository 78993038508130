export class UrunDetay{

	id:number;
	urunTitle:string;
	urunMetaDesc;
	urunUstGorselPath:string;
	urunUstGorselAdi:string;
	urunListeGorselPath:string;
	urunListeGorselAdi:string;
  	urunBaslik:string;
	urunMetni:string;
    urunOzeti:string;
    gorselBuyukBase64:string;
    gorselKucukBase64:string;	

}