<section id="slider" [ngClass]="'desktopSl'">
    <p-carousel [value]="slider" [numVisible]="1" [numScroll]="1" [responsiveOptions]="responsiveOptions" [autoplayInterval]="3000" [circular]="false">
        <ng-template let-slide of slider pTemplate="slide">
            <div [ngClass]="'carousel-cell slide'" [ngStyle]="{ 'background-image': 'url(data:image/jpeg;base64,'+slide.gorselMasaustuBase64+')' }">
                <div [ngClass]="'slider-text'">
                    <h1>{{slide.sliderBaslikUst}}</h1>
                    <h2>{{slide.sliderBaslikAlt}}</h2>
                </div>
            </div>
        </ng-template>
    </p-carousel>
</section>

<section id="slider" [ngClass]="'mobilSl'">
    <p-carousel [value]="slider" [numVisible]="1" [numScroll]="1" [responsiveOptions]="responsiveOptions" [autoplayInterval]="3000" [circular]="false">
        <ng-template let-slide of slider pTemplate="slide">
            <div [ngClass]="'carousel-cell slide'" [ngStyle]="{ 'background-image': 'url(data:image/jpeg;base64,'+slide.gorselMobilBase64+')' }">
                <div [ngClass]="'slider-text'">
                    <h1>{{slide.sliderBaslikUst}}</h1>
                    <h2>{{slide.sliderBaslikAlt}}</h2>
                </div>
            </div>
        </ng-template>
    </p-carousel>
</section>