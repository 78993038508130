export class HaberDetay{

    id:number;
    haberKucukResimPath:string;
    haberKucukResimAdi:string;
    haberBuyukResimPath:string;
    haberBuyukResimAdi:string;
    haberBaslik:string;
    haberMetni:string;
    haberTarih:Date;
    gorselKucukBase64:String;
    gorselBuyukBase64:string;

}