import { KullaniciModel } from "./kullanicimodel";
import { Stok } from 'src/app/models/stok';

export class TeklifModel{

    id:number;
    cariModel:KullaniciModel;
    stokLar:Stok[];
    mesaj:string;
    teklifAciklama:string;
    teklifKodu:string;
    musteriAlacakMi:boolean;
    sipariseDonumstumu:boolean;
    teklifeDonumstumu:boolean;
    iptal:boolean;
    iptalTarihi:Date;
    teklifNo:string;
    siparisNo:string;
    tarih:Date;
    tutar:number;
    kdv:number;
    genelToplam:number;
    durum:number=0;

}