<section id="content">
	<figure [ngStyle]="{'background-image': 'url(/assets/images/iletisim.jpg)'}">
		<div [ngClass]="'breadcrumb'">
			<h1>İLETİŞİM</h1>
			<ul id="breadcrumb" itemscope itemtype="http://schema.org/BreadcrumbList">
				<li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item"  routerLink="/" title="Ana Sayfa"><span  itemprop="name">Ana Sayfa</span></a><meta itemprop="position" content="1" /></li>
				<li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><meta itemprop="position" content="Hakkımızda" />İletişim</li>
			</ul>
		</div>
	</figure>
	<div [ngClass]="'contact'">
		<ul>
			<li>
				<h1>İLETİŞİM BİLGİLERİ</h1>
				<h2>MERTSE SAC METAL - DEMİRCİ ŞUBE</h2>
				<address><i [ngClass]="'fthsc-konum'"></i><a href="https://www.google.com/maps/dir/Current+Location/40.175955,28.930264" title="Yol Tarifi" target="_blank">Demirci Mh. Dere Sk. No:102 Nilüfer / Bursa / Türkiye</a></address>
				<div [ngClass]="'mail'"><i [ngClass]="'fthsc-eposta'"></i><a href="mailto:uretim@mertse.com" title="Mertse E-Posta">uretim@mertse.com</a></div>
				<div [ngClass]="'phone'"><i [ngClass]="'fthsc-telefon'"></i><a href="tel:+908508889393" title="Mertse Müşteri Hizmetleri">0(850) 888 93 93</a></div>
				<h2>MERTSE DEMİR MARKET - VAKIF ŞUBE</h2>
				<address><i [ngClass]="'fthsc-konum'"></i><a href="https://www.google.com/maps/dir/Current+Location/40.2018432,29.1344084" title="Yol Tarifi" target="_blank">Çınarönü Mh. Vakıf Cd. No:129/A Yıldırım / Bursa / Türkiye</a></address>
				<div [ngClass]="'mail'"><i [ngClass]="'fthsc-eposta'"></i><a href="mailto:oguz@mertse.com" title="Mertse E-Posta">oguz@mertse.com</a></div>
				<div [ngClass]="'mail'"><i [ngClass]="'fthsc-eposta'"></i><a href="mailto:caner@mertse.com" title="Mertse E-Posta">caner@mertse.com</a></div>
				<div [ngClass]="'mail'"><i [ngClass]="'fthsc-eposta'"></i><a href="mailto:ziya@mertse.com" title="Mertse E-Posta">ziya@mertse.com</a></div>
				<div [ngClass]="'mail'"><i [ngClass]="'fthsc-eposta'"></i><a href="mailto:mesut@mertse.com" title="Mertse E-Posta">mesut@mertse.com</a></div>
				<div [ngClass]="'mail'"><i [ngClass]="'fthsc-eposta'"></i><a href="mailto:samet@mertse.com" title="Mertse E-Posta">samet@mertse.com</a></div>
				<div [ngClass]="'phone'"><i [ngClass]="'fthsc-telefon'"></i><a href="tel:+908508889393" title="Mertse Müşteri Hizmetleri">0(850) 888 93 93</a></div>
				
			</li>
			<li>
				
				<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8155.905876755985!2d28.92574752392457!3d40.176490144127506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14ca10db20601bdd%3A0x58f4986a5af0bb90!2sMertse%20Sac%20Metal!5e0!3m2!1str!2str!4v1625840978181!5m2!1str!2str" width="100%" height="250" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
				<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d957.0586472492922!2d29.134187996747958!3d40.20184273797787!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14ca3ed43f28f837%3A0x702eab27e45f9ccb!2sMertse%20Demir%20Market!5e0!3m2!1str!2str!4v1625841019973!5m2!1str!2str" width="100%" height="250" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
			</li>
		</ul>		
	</div>
</section>