<section id="content">
    <figure [ngStyle]="{ 'background-image': 'url(./assets/images/hizmetlerimiz.jpg)' }">
        <div [ngClass]="'breadcrumb'">
            <h1>HİZMETLERİMİZ</h1>
            <ul id="breadcrumb" itemscope itemtype="http://schema.org/BreadcrumbList">
                <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item" routerLink="/" title="Ana Sayfa"><span  itemprop="name">Ana Sayfa</span></a>
                    <meta itemprop="position" content="1" />
                </li>
                <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
                    <meta itemprop="position" content="Hizmetlerimiz" />Hizmetlerimiz</li>
            </ul>
        </div>
    </figure>
    <div id="article-text" [ngClass]="'services'" class="list-group">
        <div [ngClass]="'srv'" class="list-group-item services" *ngFor="let hizmet of hizmetler; let i = index" [class.active]="i == currentIndex">

            <figure [ngStyle]="{ 'background-image': 'url(data:image/jpeg;base64,'+hizmet.gorselKucukBase64+')' }"></figure>
            <content>
                <h2 [innerHTML]="hizmet.hizmetBaslik">{{hizmet.hizmetBaslik}}</h2>
                <div [innerHTML]="hizmet.hizmetOzeti">{{hizmet.hizmetOzeti}}</div>
            </content>
            <div>
                <a [routerLink]="['/hizmetlerimiz/hizmetDetay', hizmet.permalink]" href="/hizmetlerimiz/hizmetDetay/{{hizmet.permalink}}">Detaylar</a>
            </div>
        </div>
    </div>
</section>