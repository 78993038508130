import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { KurumsalService } from 'src/app/components/services/kurumsal.service';
import { Kurumsal } from '../components/kurumsal/kurumsal';
import { GlobalConstants } from 'src/app/common/global-constansts';

@Component({
  selector: 'app-kullanim-kosullari',
  templateUrl: './kullanim-kosullari.component.html',
  styleUrls: ['./kullanim-kosullari.component.css']
})
export class KullanimKosullariComponent implements OnInit {

  title='Mertse | Kullanım Koşulları'

  currentKurumsal = {
    "id":"0",
    "kurumsalGorselAdi":"",
    "kurumsalIcerikTitle":"",
    "kurumsalIcerikMetaDesc":"",
    "kurumsalIcerikBaslik":"",
    "kurumsalIcerikMetni":"",
    "gorselBase64":""
    };

  constructor(
    private titleService: Title,
    private kurumsalServis: KurumsalService,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.getKurumsal(6);
    this.metaTagService.updateTag(
      {
        name: 'description',
        content: this.currentKurumsal.kurumsalIcerikMetaDesc
      }
    );
  }
  getKurumsal(id){
    this.kurumsalServis.read(id)
      .subscribe(
        kurumsal => {
          this.currentKurumsal = kurumsal;
          this.titleService.setTitle(this.currentKurumsal.kurumsalIcerikTitle);
        },
        error => {
        });
  }

}
