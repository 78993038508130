import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { HizmetlerimizService } from 'src/app/components/services/hizmetlerimiz.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Hizmetlerimiz } from './hizmetlerimiz';
import { GlobalConstants } from 'src/app/common/global-constansts';

@Component({
  selector: 'app-hizmetlerimiz',
  templateUrl: './hizmetlerimiz.component.html',
  styleUrls: ['./hizmetlerimiz.component.css']
})
export class HizmetlerimizComponent implements OnInit {
  

  hizmetler:Hizmetlerimiz[];
  title='Mertse | Hizmetlerimiz'
  currentIndex = -1;
  currentHizmetlerimiz = {
    "id":"",
    "hizmetUstGorselPath":"",
    "hizmetUstGorselAdi":"",
    "hizmetListeGorselPath":"",
    "hizmetListeGorselAdi":"",
    "hizmetBaslik":"",
    "hizmetMetni":"",
    "hizmetOzeti":"",
    "gorselBuyukBase64":"",
    "gorselKucukBase64":"",
    "permalink":""};  
    
    constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private hizmetlerimizServis: HizmetlerimizService,
    private route: ActivatedRoute,
    private router: Router
    
  ) {

   }

  ngOnInit() {
    this.readHizmetler();
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Satışını yaptığımız tüm ürünler.' }
    );
    

  }
  
  readHizmetler(): void {
    this.hizmetlerimizServis.readAll()
      .subscribe(
        data => {
          this.hizmetler = data;
        },
        error => {
        });
  }

}

