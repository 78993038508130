<div [ngClass]="'mobile-top'">
    <div [ngClass]="'social'">
        <a href="https://www.facebook.com/mertsemetal/" target="_blank" title="Mertse Facebook"><i [ngClass]="'fthsc-facebook'"></i></a>
        <a href="https://twitter.com/mertsemetal" target="_blank" title="Mertse Twitter"><i [ngClass]="'fthsc-twitter'"></i></a>
        <a href="https://youtube.com/channel/UCjeLxG9nJoHAqPAHmMuws7g" target="_blank" title="Mertse Youtube"><i [ngClass]="'fthsc-youtube'"></i></a>
        <a href="https://tr.linkedin.com/company/mertse" target="_blank"  title="Mertse LinkedIn"><i [ngClass]="'fthsc-linkedin'"></i></a>
    </div>
</div>
<ul id="navbar-list" class="navbar-nav override">
    <li class="nav-item override" (click)="onNavLinkClicked($event)"><a [routerLink]="['/']" href="/" class="nav-link override" title="Mertse Ana Sayfa">Ana Sayfa</a></li>
    <li class="nav-item override" (click)="onNavLinkClicked($event)"><a [routerLink]="['/kurumsal']" href="/kurumsal" class="nav-link override" title="Kurumsal">Kurumsal</a></li>
    <li class="nav-item override" (click)="onNavLinkClicked($event)"><a [routerLink]="['/kurumsal/hakkimizda']" href="/kurumsal/hakkimizda" class="nav-link override" title="Hakkımızda">Hakkımızda</a></li>
    <li class="nav-item override" (click)="onNavLinkClicked($event)"><a [routerLink]="['/kurumsal/misyon-vizyon']" href="/kurumsal/misyon-vizyon" class="nav-link override" title="Misyon & Vizyon">Misyon & Vizyon</a></li>
    <li class="nav-item override" (click)="onNavLinkClicked($event)"><a [routerLink]="['/kurumsal/organizasyon-semasi']" href="/kurumsal/organizasyon-semasi" class="nav-link override" title="Organizasyon Şeması">Organizasyon Şeması</a></li>
    <li class="nav-item override" (click)="onNavLinkClicked($event)"><a [routerLink]="['/kurumsal/makine-parkurumuz']" href="/kurumsal/makine-parkurumuz" class="nav-link override" title="Makine Parkurumuz">Makine Parkurumuz</a></li>   
    <li class="nav-item override" (click)="onNavLinkClicked($event)"><a [routerLink]="['/hizmetlerimiz']" href="/hizmetlerimiz" class="nav-link override" title="Hizmetlerimiz">Hizmetlerimiz</a></li>
    <li class="nav-item override" (click)="onNavLinkClicked($event)"><a [routerLink]="['/urunler']" href="/urunler" class="nav-link override" title="Ürünler">Ürünler</a></li>
    <li class="nav-item override" (click)="onNavLinkClicked($event)"><a [routerLink]="['/haberler']" href="/haberler" class="nav-link override" title="Haberler">Haberler</a></li>
    <li class="nav-item override" (click)="onNavLinkClicked($event)"><a [routerLink]="['/bayi']" href="/bayi" class="nav-link override" title="Bayi Portalı">Bayi Portalı</a></li> 
    <li class="nav-item override" (click)="onNavLinkClicked($event)"><a [routerLink]="['/contact']" href="/contact" class="nav-link override" title="İletişim">İletişim</a></li>
    <!--li class="nav-item override" (click)="onNavLinkClicked($event)"><a [routerLink]="['/spor-kulubu']" href="/spor-kulubu" class="nav-link override" title="Spor Kulübü">Spor Kulübümüz</a></li-->
</ul>