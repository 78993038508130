import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { SporKulubuService } from '../services/spor-kulubu.service';
import { SporKulubu } from './spor-kulubu';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalConstants } from 'src/app/common/global-constansts';

@Component({
  selector: 'app-spor-kulubu',
  templateUrl: './spor-kulubu.component.html',
  styleUrls: ['./spor-kulubu.component.css']
})
export class SporKulubuComponent implements OnInit {

  sporKulubu:SporKulubu[];
  title='Mertse | Spor Kulübümüz'
  currentIndex = -1;
  currentSporKulubu = {
    "id":"",
    "sporKulubuTitle":"",
    "sporKulubuMetaDesc":"",
    "sporKulubuBaslik":"",
    "sporKulubuMetni":"",
    "sporKulubuOzet":"",
    "sporKulubuUstGorselPath":"",
    "sporKulubuUstGorselAdi":"",
    "sporKulubuListeGorselPath":"",
    "sporKulubuListeGorselAdi":"",
    "gorselBuyukBase64":"",
    "gorselKucukBase64":"",
    "permalink":""
  };
    
    constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private sporKulubuServis: SporKulubuService,
    private route: ActivatedRoute,
    private router: Router
    
  ) {

   }

  ngOnInit() {
    this.readSporKulubu();
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Spor Kulubümüzle ilgili tüm içerikler.' }
    );
    

  }
  
  readSporKulubu(): void {
    this.sporKulubuServis.readAll()
      .subscribe(
        data => {
          this.sporKulubu = data;

        },
        error => {
        });
  }

}

