import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalConstants } from 'src/app/common/global-constansts';
import { SliderService } from '../services/slider.service';
import { Slider } from './Slider';
import { Carousel } from 'primeng/carousel';


@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})

export class SliderComponent implements OnInit {

  slider:Slider[];

  responsiveOptions;

  title='Mertse | Slider'
  currentIndex = -1;
  currentSlider = {
    "id":"",
    "sliderDesktopPath":"",
    "sliderDesktopAdi":"",
    "sliderMobilePath":"",
    "sliderMobileAdi":"",
    "sliderTabletPath":"",
    "sliderTabletAdi":"",
    "sliderBaslikUst":"",
    "sliderBaslikAlt":"",
    "gorselMasaustuBase64":"",
    "gorselMobilBase64":"",
    "gorselTabletBase64":"",
    
  };

  constructor(
    private sliderServis: SliderService,
    private route: ActivatedRoute,
    private router: Router
  ) { 
    Carousel.prototype.onTouchMove = () => { };
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '768px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ]
  }

  ngOnInit(): void {
    this.readSlider();
  }

  readSlider(): void {
    this.sliderServis.readAll()
      .subscribe(
        data => {
          this.slider = data;
          
        },
        error => {
        });
  }

  
}
