<section id="content">
    <figure [ngStyle]="{ 'background-image': 'url(data:image/jpeg;base64,'+currentSporKulubuDetay.gorselBuyukBase64+')' }">
        <div [ngClass]="'breadcrumb'">
            <h1>{{currentSporKulubuDetay.sporKulubuBaslik}}</h1>
            <ul id="breadcrumb" itemscope itemtype="http://schema.org/BreadcrumbList">
                <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item" routerLink="/" title="Ana Sayfa"><span  itemprop="name">Ana Sayfa</span></a>
                    <meta itemprop="position" content="1" />
                </li>
                <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item" routerLink="/spor-kulubu" title="Spor Kulübümüz"><span itemprop="name">Spor Kulübümüz</span></a>
                    <meta itemprop="position" content="2" />
                </li>
                <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
                    <meta itemprop="position" content="3" />{{currentSporKulubuDetay.sporKulubuBaslik}}</li>
            </ul>
        </div>
    </figure>
    <div id="article-text" [innerHTML]="currentSporKulubuDetay.sporKulubuMetni">
        {{currentSporKulubuDetay.sporKulubuMetni}}
    </div>
</section>