<div [ngClass]="'header-bg'"></div>

<section>
    <div [ngClass]="'b2b-account-area'">
		<h1>BAYİ / HESAP AYARLARI</h1>
        <h2 *ngIf="!loading">Ünvan: {{cari.unvan}}</h2>
        <div *ngIf="!loading" [ngClass]="'b2b-account'">
            <ul>
                <li>
                    <ul>
                        <li>Cari Kodu:</li>
                        <li>: {{cari.cariKodu}}</li>
                        <li>Ünvan</li>
                        <li>: {{cari.unvan}}</li>
                        <li>T.C. No</li>
                        <li>: {{cari.tcNo}}</li>
                        <li>Vergi D.</li>
                        <li>: {{cari.vergiDaire}}</li>
                        <li>Vergi No</li>
                        <li>: {{cari.vergiNo}}</li>
                        <li>Sevk Adresi</li>
                        <li>: {{cari.sevkAdresi}}</li>
                        <li>Fatura Adresi</li>
                        <li>: {{cari.faturaAdresi}}</li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <li>M. Temsilcisi</li>
                        <li>:{{cari.temsilci}}</li>
                        <li>E-Posta</li>
                        <li>: {{cari.eposta}}</li>
                        <li>Cep Tel</li>
                        <li>: {{cari.cepTel}}</li>
                        <li>İş Tel</li>
                        <li>: {{cari.isTel}}</li>
                        <li>Ödeme Tipi</li>
                        <li>: {{cari.odemeTipi}}</li>
                        <li>Vade</li>
                        <li>: {{cari.vadeGun}}</li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <li>Açık Hesap Limit</li>
                        <li>: {{cari.acikHesapLimit| number:'.2-2'}}₺</li>
                        <li>K. Çeki Risk Limit</li>
                        <li>: {{cari.kendiCekLimiti| number:'.2-2'}}₺</li>
                        <li>M. Çeki Risk Limit</li>
                        <li>: {{cari.musteriCekLimiti| number:'.2-2'}}₺</li>
                      
                    </ul>
                </li>
            </ul>
            <div [ngClass]="'account-summary'">
                <ul>
                    <li>Bakiye</li>
                    <li>Kendi Çeki Risk</li>
                    <li>Müşteri Çeki Risk</li>
                    <li>Geciken</li>
                    <li>Ortalama Vade</li>
                    <li>{{cari.bakiye| number:'.2-2'}}₺ (B)</li>
                    <li>{{cari.kendiCekRisk| number:'.2-2'}}₺</li>
                    <li>{{cari.musteriCekRisk| number:'.2-2'}}₺</li>
                    <li>{{cari.geciken| number:'.2-2'}}₺</li>
                    <li>{{cari.ortalamaVade| date:'dd/MM/yyyy'}}</li>

                </ul>
                <ul>
                    <li>Bakiye Boşluk</li>
                    <li>K.Çek Boşluk</li>
                    <li>M.Çek Boşluk</li>
                    <li>{{cari.acikHesapBosluk| number:'.2-2'}}₺</li>
                    <li>{{cari.kendiCekBosluk| number:'.2-2'}}₺</li>
                    <li>{{cari.musteriCekBosluk| number:'.2-2'}}₺</li>
                </ul>
            </div>
                
            <div [ngClass]="'account-detail'">
                <table>
                    <tr>
                        <th>Tarih</th>
                        <th>Belge No</th>
                        <th>Belge Türü</th>
                        <th>Satır Açıklama</th>
                        <th>Vade Tarihi</th>
                        <th>Vade Gün</th>
                        <th>Borç Alacak</th>
                        <th>Borç</th>
                        <th>Alacak</th>
                        <th>Borç Bakiye</th>
                        <th>Alacak Bakiye</th>
                        <th>Kalan</th>
                        <th>Gecikme Gün</th>
                        <th>Açıklama</th>
                    </tr>
                    <ng-container *ngFor="let hareket of cari.hareketler">
                    <tr style="{{hareket.gecikmeGun<0 ?'background-color: #a30d0d;color:white':''}}">
                        <td>{{hareket.tarih| date:'dd/MM/yyyy'}}</td>
                        <td>{{hareket.belgeNo}}</td>
                        <td>{{hareket.belgeTuru}}</td>
                        <td>{{hareket.satirAciklama}}</td>
                        <td>{{hareket.vadeTarihi| date:'dd/MM/yyyy'}}</td>
                        <td>{{hareket.vadeGun}}</td>
                        <td>{{hareket.borc!=0?'Borç':'Alacak'}}</td>
                        <td>{{hareket.borc| number:'.2-2'}}₺</td>
                        <td>{{hareket.alacak| number:'.2-2'}}₺</td>
                        <td>{{hareket.borcBakiye| number:'.2-2'}}₺</td>
                        <td>{{hareket.alacakBakiye*-1| number:'.2-2'}}₺</td>
                        <td>{{hareket.gecikme| number:'.2-2'}}₺</td>
                        <td>{{hareket.gecikmeGun*-1}}</td>
                        <td>{{hareket.aciklama}}</td>
                    </tr>
                    </ng-container>
                </table>
            </div>
        </div>
        <div class="spinner" ng-show="loading" style="text-align: center; align-items: center; min-height: 100% !important;">
            <img src="/assets/images/codemine.gif"/>
          </div>
    </div>
</section>

