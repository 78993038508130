export class KullaniciModel{

	id : number;
	cariId : number;
	kullaniciAdi : string;
	sifre : string;
	adi : string;
	soyadi : string;
	firmaAdi : string;
	teslimatAdresi : string;
	yetki : string;
	session : string;
	bayi:boolean;
}