import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { KurumsalService } from 'src/app/components/services/kurumsal.service';
import { Kurumsal } from '../kurumsal/kurumsal';
import { GlobalConstants } from 'src/app/common/global-constansts';

@Component({
  selector: 'app-organizasyon-semasi',
  templateUrl: './organizasyon-semasi.component.html',
  styleUrls: ['./organizasyon-semasi.component.css']
})
export class OrganizasyonSemasiComponent implements OnInit {

  title='Mertse | Organizasyon Şeması'

  currentKurumsal = {
  "id":"0",
  "kurumsalGorselAdi":"",
  "kurumsalIcerikTitle":"",
  "kurumsalIcerikMetaDesc":"",
  "kurumsalIcerikBaslik":"",
  "kurumsalIcerikMetni":"",
  "gorselBase64":""
  };

  constructor(
    private titleService: Title,
    private kurumsalServis: KurumsalService,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.getKurumsal(4);
    this.metaTagService.updateTag(
      {
        name: 'description',
        content: this.currentKurumsal.kurumsalIcerikMetaDesc
      }
    );
  }
  getKurumsal(id){
    this.kurumsalServis.read(id)
      .subscribe(
        kurumsal => {
          this.currentKurumsal = kurumsal;
          this.titleService.setTitle(this.currentKurumsal.kurumsalIcerikTitle);
        },
        error => {
        });
  }

} 