<section id="content">
	<figure [ngStyle]="{ 'background-image': 'url(./assets/images/urunler.jpg)' }">
		<div [ngClass]="'breadcrumb'">
		<h1>ÜRÜNLER</h1>
			<ul id="breadcrumb" itemscope itemtype="http://schema.org/BreadcrumbList">
				<li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item"  routerLink="/" title="Ana Sayfa"><span  itemprop="name">Ana Sayfa</span></a><meta itemprop="position" content="1" /></li>
				<li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><meta itemprop="position" content="2" />Ürünler</li>
			</ul>
		</div>
	</figure>
	<div id="article-text" [ngClass]="'products'"> 
		<div [ngClass]="'prods'"class="list-group-item services" *ngFor="let urun of urunler; let i = index" [class.active]="i == currentIndex">
			<figure [ngStyle]="{ 'background-image': 'url(data:image/jpeg;base64,'+urun.gorselKucukBase64+')' }"></figure>
			<content>
				<h2 [innerHTML]="urun.urunBaslik">{{urun.urunBaslik}}</h2>
				<div [innerHTML]="urun.urunOzeti">
					{{urun.urunOzeti}}
				</div>
			</content>
			<div>
				<a [routerLink]="['/urunler/urun-detay', urun.permalink]">Detaylar</a>
			</div>
		</div>
	</div>
</section>
