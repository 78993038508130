<section id="content">
    <figure [ngStyle]="{ 'background-image': 'url(./assets/images/haberler-ust.jpg)' }">
        <div [ngClass]="'breadcrumb'">
            <h1>HABERLER</h1>
            <ul id="breadcrumb" itemscope itemtype="http://schema.org/BreadcrumbList">
                <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item" routerLink="/" title="Ana Sayfa"><span  itemprop="name">Ana Sayfa</span></a>
                    <meta itemprop="position" content="1" />
                </li>
                <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
                    <meta itemprop="position" content="Haberler" />Haberler</li>
            </ul>
        </div>
    </figure>
    <div id="article-text" [ngClass]="'services'" class="list-group">
        <div [ngClass]="'srv'" class="list-group-item services" *ngFor="let haber of haberler; let i = index" [class.active]="i == currentIndex">

            <figure [ngStyle]="{ 'background-image': 'url(data:image/jpeg;base64,'+haber.gorselKucukBase64+')' }"></figure>
            <content>
                <h2 [innerHTML]="haber.haberBaslik">{{haber.haberBaslik}}</h2>
                <time datetime="{{haber.haberTarih | date: 'dd / MM / yyyy'}}">{{haber.haberTarih | date: 'dd / MM / yyyy'}}</time>

                <div [innerHTML]="haber.haberOzeti">{{haber.haberOzeti}}</div>
            </content>
            <div>
                <a [routerLink]="['/haberler/haber-detay', haber.permalink]" href="/haberler/haber-detay/{{haber.permalink}}" title="{{haber.haberBaslik}}">Detaylar</a>
            </div>
        </div>
    </div>
</section>