import { Injectable } from '@angular/core';
import { Hizmetlerimiz } from '../hizmetlerimiz/hizmetlerimiz';
import { HttpClient, HttpParams } from '@angular/common/http'; //Adım 2
import { Observable } from 'rxjs'; //Adım 3
import { map,catchError,tap } from "rxjs/operators"; //Adım 4
import { GlobalConstants } from '../../common/global-constansts';
@Injectable({
  providedIn: 'root'
})
export class HizmetlerimizService {

  baseURL:string='';

  constructor(private httpClient: HttpClient) {
    this.baseURL=GlobalConstants.apiURL+'/getHizmetler';
    console.log(GlobalConstants.apiURL);
   }
  
  readAll(): Observable<Hizmetlerimiz[]> {
    return this.httpClient.get<Hizmetlerimiz[]>(this.baseURL);
  }


}
