import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { GlobalConstants } from 'src/app/common/global-constansts';
import { SporKulubuDetayService } from '../services/spor-kulubu-detay.service';
import { SporKulubuDetay } from './SporKulubuDetay';


@Component({
  selector: 'app-spor-kulubu-detay',
  templateUrl: './spor-kulubu-detay.component.html',
  styleUrls: ['./spor-kulubu-detay.component.css']
})
export class SporKulubuDetayComponent implements OnInit {

  title='Mertse | Spor Kulübü Detay'
  currentIndex = -1;
  currentSporKulubuDetay:SporKulubuDetay = new SporKulubuDetay(); 

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private sporKulubuDetayServis: SporKulubuDetayService,
    private route: ActivatedRoute,
    private router: Router
  ) { 

  }

  ngOnInit(): void {
    this.readSporKulubuDetay();
    this.metaTagService.updateTag(
      {
        name: 'description',
        content: this.currentSporKulubuDetay.sporKulubuMetaDesc
      }
    );
  }

  readSporKulubuDetay(): void {

    const pln = this.route.snapshot.paramMap.get('permalink');

   this.sporKulubuDetayServis.getSporDetay(pln)
     .subscribe(
       data => {
         this.currentSporKulubuDetay = data;
       },
       error => {
         
       });
 }


}
