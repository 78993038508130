<div [ngClass]="'header-bg'"></div>

<section>
    <p-toast position="center"></p-toast> 
    <div [ngClass]="'offer-area'">
        <h1>BAYİ BAĞLANTI TALEBİ</h1>
        <app-cari-bilgi></app-cari-bilgi>
        <ul>
            <li>
                <h1>Ana Stok Grubu</h1>
            </li>
            <li>
                <p-dropdown [options]="anaStokGruplari" [(ngModel)]="anaStokGrup" 
                    placeholder="Stok Ana Grubu Seçiniz" optionLabel="adi" [showClear]="true" 
                    (onChange)="anaStokDegisti()" [filter]="true"></p-dropdown>
            </li>
            <li>
                <h1>Stok Grubu</h1>
            </li>
            <li>
                <p-dropdown [options]="stokGruplari" [(ngModel)]="talep.stokGrubu" (onChange)="stokGrubuDegisti()"
                    placeholder="Stok Grubu Seçiniz" optionLabel="adi" [showClear]="true" [filter]="true"></p-dropdown>
            </li>
            <li>
                <h1>Fiyat Listesi</h1>
            </li>
            <li>
                <p-dropdown [options]="stokGrubuFiyatIskontolari" [(ngModel)]="talep.fiyatIskontoListesi" 
                    placeholder="Fiyat Listesi Seçiniz" 
                    optionLabel="oran" [showClear]="true" [filter]="true">
                    <ng-template let-fiyatIskonto pTemplate="item">
                        <div>
                            <div>{{fiyatIskonto.uretici}}</div>
                            <div>{{fiyatIskonto.fiyatListeAdi}}</div>
                            <div>{{fiyatIskonto.oran}} %</div>
                        </div>
                    </ng-template>
                
                </p-dropdown>
            </li>

            <li>
                <h1>Birim</h1>
            </li>
            <li>
                <p-dropdown [options]="birimler" [(ngModel)]="talep.birim" placeholder="Birim Seçiniz" [showClear]="true"  optionLabel="birim" ></p-dropdown>
            </li>
            <li>
                <h1>Miktar</h1>
            </li>
            <li>
                <p-inputNumber [(ngModel)]="talep.toplamMiktar" placeholder="Miktar" size="7"></p-inputNumber>
            </li>
            <li class="add">
                <button type="button" label="Talep Oluştur" pButton pRipple (click)="kaydet()" 
                class="p-button-info" icon="pi pi-plus"></button></li>
           

        </ul>
    </div>
    
</section>       
