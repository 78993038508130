import { Component, HostBinding, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from './shared/canonical.service';
import { PrimeNGConfig } from 'primeng/api';
import { TokenStorageService } from './services/token-storage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  name = 'NavigationBarProject';
  title = 'Mertse Ana Sayfa';
  
  isLogin:boolean=false;

  @HostBinding("class.drawer-open")
  isDrawerOpen: boolean = false;
  
  toggleDrawer(isDrawerOpen: boolean) {
    this.isDrawerOpen = isDrawerOpen;
  }

  getDummyText(): string {
    return "";
  }
  

  constructor(
    private titleService: Title, 
    private metaService: Meta, 
    private canonicalService: CanonicalService,
    private primengConfig: PrimeNGConfig,
    public tokenStorage: TokenStorageService,
    public translateService: TranslateService
    ){

      translateService.addLangs(['tr']);
      translateService.setDefaultLang('tr');
      const browserLang = translateService.getBrowserLang();
      translateService.use('tr');
//      translate.use(browserLang.match(/tr/) ? browserLang : 'en');

    }
  
    
  ngOnInit() {

    this.primengConfig.ripple = true;
    this.translateService.get('primeng').subscribe(res => this.primengConfig.setTranslation(res));
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'keywords', content: 'lazer kesim, sac metal, endüstriyel metal kesim, optik kesim, plazma kesim, boru lazer, ebatlama, doğalgaz boruları, levha sac, köşebent, silme, lama, npi, npu , hea, heb '},
      {name: 'description', content: '1993 yılında Ekrem Senal tarafından kurularak 110 m² işyeri ile demir çelik sektöründeki yerini almıştır. Firma bugün 15.000 m² kare kapalı 10.000 m² açık alan olmak üzere toplam 25.000 m² alana çıkarak sektördeki ciddiyetini orataya koymuştur.'},
    
    ]);
    this.canonicalService.setCanonicalURL();
    this.tokenStorage.getUser();
  }

  translate(lang: string) {
    this.translateService.use(lang);
    this.translateService.get('primeng').subscribe(res => this.primengConfig.setTranslation(res));
  }
}
