<section id="content">
    <figure [ngStyle]="{ 'background-image': 'url(data:image/jpeg;base64,'+currentKurumsal.gorselBase64+')' }">
        <div [ngClass]="'breadcrumb'">
            <h1>{{currentKurumsal.kurumsalIcerikBaslik}}</h1>
            <ul id="breadcrumb" itemscope itemtype="http://schema.org/BreadcrumbList">
                <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item" routerLink="/" title="Ana Sayfa"><span  itemprop="name">Ana Sayfa</span></a><meta itemprop="position" content="1" /></li>
                <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><meta itemprop="position" content="{{currentKurumsal.kurumsalIcerikBaslik}}" />{{currentKurumsal.kurumsalIcerikBaslik}}<meta itemprop="position" content="2" /></li>
            </ul>
        </div>
    </figure>
        <div id="article-text" [innerHTML]="currentKurumsal.kurumsalIcerikMetni" >
        {{currentKurumsal.kurumsalIcerikMetni}}
    </div>
</section>