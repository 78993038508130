<nav class="navbar navbar-expand-md" id="navbar">
    <div class="container">
      <div class="nav-toggler" [class.drawer-open]="isDrawerOpen" id="nav-toggler" (click)="toggleNavDrawer(!isDrawerOpen)">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
      </div>
      <nav-drawer [isDrawerOpen]="isDrawerOpen" (drawerToggleEmitter)="toggleNavDrawer($event)"></nav-drawer>
    </div>
  </nav>