import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'; //Adım 2
import { Observable } from 'rxjs'; //Adım 3
import { map,catchError,tap } from "rxjs/operators"; //Adım 4
import { GlobalConstants } from 'src/app/common/global-constansts';
import { SporKulubuDetay } from '../spor-kulubu-detay/SporKulubuDetay';

@Injectable({
  providedIn: 'root'
})
export class SporKulubuDetayService {
  baseURL:string='';


  constructor(private httpClient: HttpClient) {
    this.baseURL=GlobalConstants.apiURL+'/getSporKulubuDetay';
    console.log(GlobalConstants.apiURL);
   }

   getSporDetay(permalink): Observable<SporKulubuDetay> {
    const options = { params: new HttpParams({fromString: "permalink="+permalink}) }; 
    return this.httpClient.get<SporKulubuDetay>(this.baseURL,options);
  }
}
