import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { HaberDetayService } from '../services/haber-detay.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { HaberDetay } from './HaberDetay';
import { GlobalConstants } from 'src/app/common/global-constansts';

@Component({
  selector: 'app-haber-detay',
  templateUrl: './haber-detay.component.html',
  styleUrls: ['./haber-detay.component.css']
})
export class HaberDetayComponent implements OnInit {

  title='Mertse | Haberler Detay'
  currentIndex = -1;
  currentHaberDetay:HaberDetay = new HaberDetay(); 

  constructor
  (
    private titleService: Title,
    private metaTagService: Meta,
    private haberDetayServis: HaberDetayService,
    private route: ActivatedRoute,
    private router: Router
  ) {

   }

  ngOnInit(): void {
    this.readHaberDetay();
  }

  
  readHaberDetay(): void {

    const pln = this.route.snapshot.paramMap.get('permalink');

   this.haberDetayServis.getHaberDetay(pln)
     .subscribe(
       data => {
         this.currentHaberDetay = data;
       },
       error => {
       });
 }

}
