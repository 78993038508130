import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'; //Adım 2
import { Observable } from 'rxjs'; //Adım 3
import { map,catchError,tap } from "rxjs/operators"; //Adım 4
import { GlobalConstants } from 'src/app/common/global-constansts';
import { HaberDetay } from '../haber-detay/HaberDetay';

@Injectable({
  providedIn: 'root'
})
export class HaberDetayService {
  baseURL:string='';


  constructor(private httpClient: HttpClient) {

    this.baseURL=GlobalConstants.apiURL+'/getHaberDetay';
    console.log(GlobalConstants.apiURL);
   }

   getHaberDetay(permalink): Observable<HaberDetay> {
    const options = { params: new HttpParams({fromString: "permalink="+permalink}) }; 
    return this.httpClient.get<HaberDetay>(this.baseURL,options);
  }
}
