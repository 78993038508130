import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'; //Adım 2
import { Observable } from 'rxjs'; //Adım 3
import { map,catchError,tap } from "rxjs/operators"; //Adım 4
import { HizmetDetay } from '../hizmet-detay/hizmetDetay';
import { GlobalConstants } from '../../common/global-constansts';


//const baseURL = 'http://localhost:8080/metalerp/metal/web/getHizmet';


@Injectable({
  providedIn: 'root'
})
export class HizmetDetayService {
  baseURL:string='';


  constructor(private httpClient: HttpClient) { 
        
    this.baseURL=GlobalConstants.apiURL+'/getHizmet';
    console.log(GlobalConstants.apiURL);
  }

  getHizmet(permalink): Observable<HizmetDetay> {
    const options = { params: new HttpParams({fromString: "permalink="+permalink}) }; 
    return this.httpClient.get<HizmetDetay>(this.baseURL,options);
  }
}
