<div [ngClass]="'header-bg'"></div>

<section>
    <div [ngClass]="'my-orders-area'">
        <app-cari-bilgi></app-cari-bilgi>
        <div [ngClass]="'my-orders-list'">
            <p-table #dt [value]="siparislerim" [rows]="10" styleClass="p-datatable-striped p-datatable-gridlines" [responsive]="true" responsiveLayout="scroll"
            [showCurrentPageReport]="true" sortMode="multiple" [resizableColumns]="true" 
            columnResizeMode="expand" 
            [paginator]="true" currentPageReportTemplate="{first} ile {last} arası. Toplam: {totalRecords} Kayıt">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Durum</th>
                        <th>Tarih</th>
                        <th>Sipariş No</th>
                        <th>Tutar</th>
                        <th>Kdv</th>
                        <th>Toplam</th>
                        <th>Sipariş İçeriği</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-siparis>
                <tr>
                    <td>          
                        <span *ngIf="siparis.durum==0" class="bekliyor">BEKLİYOR</span>
                        <span *ngIf="siparis.durum==1" class="tamamlandi">TAMAMLANDI</span>
                        <span *ngIf="siparis.durum==2" class="iptal-edildi">İPTAL EDİLDİ</span>
                    </td>
                    <td>{{siparis.tarih | date:'dd/MM/yyyy'}}</td>
                    <td>{{siparis.siparisNo}}</td>
                    <td>{{siparis.tutar| number:'.2-2'}}₺</td>
                    <td>{{siparis.kdv| number:'.2-2'}}₺</td>
                    <td>{{siparis.genelToplam| number:'.2-2'}}₺</td>
                    <td style="">
                        <table styleClass="p-datatable-gridlines" class="siparis-detay">
                            <tr class="siparis-detay-header">
                                <th>Stok Kodu</th>
                                <th>Stok Adı</th>
                                <th>Adet</th>
                                <th>Kg</th>
                                <th>Metre</th>
                                <th>Ad Fiyat</th>
                                <th>Kg Fiyat</th>
                                <th>M Fiyat</th>
                            </tr>
                            <ng-container *ngFor="let stok of siparis.stokLar; let i = index">
                                <tr class="siparis-detay-content" style="{{i%2 === 0 ?'background-color: #cfcfcf;':'background-color: #ffffff;'}}">
                                    <td>{{stok.cariStok.kodu}}</td>
                                    <td >{{stok.cariStok.adi}}</td>
                                    <td>{{stok.adet}}</td>
                                    <td>{{stok.kgMiktar}}</td>
                                    <td>{{stok.mtMiktar}}</td>
                                    <td>{{stok.adBirimFiyat| number:'.2-2'}}₺</td>
                                    <td>{{stok.kgBirimFiyat | number:'.2-2'}}₺</td>
                                    <td >{{stok.mtBirimFiyat | number:'.2-2'}}₺</td>
                                </tr>
                            </ng-container>
                        </table>
                    </td>
                   
                </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</section>

