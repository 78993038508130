<div [ngClass]="'header-bg'"></div>

<section>
	<div [ngClass]="'dealer-area'">
		<h1>BAYİ / MÜŞTERİ PORTALI</h1>
		<app-cari-bilgi></app-cari-bilgi>
		<ul>
			<li  *ngIf="!cari.bayi" >
				<a href="teklif-iste" routerLink="teklif-iste"></a>
				<figure><img src="/assets/images/invoice.svg" alt="Teklif İste"></figure>
				<h2>TEKLİF İSTE</h2>
			</li>
			<li  *ngIf="cari.bayi" >
				<a href="baglanti-talep" routerLink="baglanti-talep"></a>
				<figure><img src="/assets/images/invoice.svg" alt="Bağlantı Talebi"></figure>
				<h2>BAĞLANTI TALEBİ</h2>
			</li>

			<li  *ngIf="!cari.bayi" >
				<a href="tekliflerim" routerLink="tekliflerim"></a>
				<figure><img src="/assets/images/reload.svg" alt="Tekliflerim"></figure>
				<h2>TEKLİFLERİM</h2>
			</li>
			<li  *ngIf="cari.bayi" >
				<a href="baglanti-taleplerim" routerLink="baglanti-taleplerim"></a>
				<figure><img src="/assets/images/invoice.svg" alt="Bağlantı Taleplerim"></figure>
				<h2>BAĞLANTI TALEPLERİM</h2>
			</li>

			<li *ngIf="cari.bayi">
				<a href="teklif-iste" routerLink="teklif-iste"></a>
				<figure><img src="/assets/images/box.svg" alt="Sipariş Oluştur"></figure>
				<h2>SİPARİŞ OLUŞTUR</h2>
			</li>

			<li>
				<a href="siparislerim" routerLink="siparislerim"></a>
				<figure><img src="/assets/images/list.svg" alt="Siparişlerim"></figure>
				<h2>SİPARİŞLERİM</h2>
			</li>
			<li>
				<a href="hesap-hareketleri" routerLink="hesap-hareketleri"></a>
				<figure><img src="/assets/images/bill.svg" alt="Hesap Hareketleri"></figure>
				<h2>HESAP HAREKETLERİ</h2>
			</li>
			<li>
				<a href="https://api.whatsapp.com/send?phone=905426963314" target="_blank"></a>
				<figure><img src="/assets/images/whatsapp.svg" alt="Whatsapp Destek"></figure>
				<h2>WHATSAPP DESTEK</h2>
			</li>
			<li>
				<a href="kullanici-ayarlari" routerLink="kullanici-ayarlari"></a>
				<figure><img src="/assets/images/settings.svg" alt="Kullanıcı Ayarları"></figure>
				<h2>KULLANICI AYARLARI</h2>
			</li>
			<!--
			<li>
				<figure><img src="/assets/images/promotions.svg" alt="Kampanyalı Ürünler"></figure>
				<h2>KAMPANYALI ÜRÜNLER</h2>
			</li>
			-->
		</ul>		
	</div>
</section>

<!--section class="duyurularPopup">
	<div id="duyuru-kontrol">
		<div class="goster"><img src="/assets/images/goster.svg" alt="Göster"></div>
		<div class="gizle"><img src="/assets/images/gizle.svg" alt="Gizle"></div>
	</div>
	<div class="duyurularPopup_govde">
		<h3>Duyurular</h3>
		<ul>
			<li>
				<time datatime="3 Mart 2021 Çarşamba">03 MART 2021</time>
				<a href="" title="">Size Özel %15 İskontolu Malzeme Listesi</a>
			</li>
			<li>
				<time datatime="6 Nisan 2021 Cuma">06 NİSAN 2021</time>
				<a href="" title="">Size Özel %15 İskontolu Malzeme Listesi</a>
			</li>
			<li>
				<a href="" title="Tümünü Göster">Tüm Duyurular</a>
			</li>
		</ul>
	</div>
</section-->