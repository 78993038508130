export class SporKulubuDetay{
	
    id:number;
	sporKulubuTitle:string;
	sporKulubuMetaDesc:string;
	sporKulubuBaslik:string;
	sporKulubuMetni:string;
	sporKulubuUstGorselPath:string;
	sporKulubuUstGorselAdi:string;
	sporKulubuListeGorselPath:string;
	sporKulubuListeGorselAdi:string;
	gorselBuyukBase64:string;
	gorselKucukBase64:string;


}