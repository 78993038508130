import { AnaKaliteGrup } from "./anaKaliteGrup";
import { CariStok } from "./cariStok";
import { KalinlikGrup } from "./kalinlikgRUP";
import { OlcuBirim } from "./olcuBirim";
export class Stok{
    id:number;
    cariStok:CariStok;
    kalinlik:KalinlikGrup;
    kalite:AnaKaliteGrup;
    tabakaEn:number;
    tabakaBoy:number;
    adet:number;
    kgMiktar:number;
    mtMiktar:number;
    anaBirim:OlcuBirim;
    birimler:OlcuBirim[];    
    adBirimFiyat:number;
    kgBirimFiyat:number;
    mtBirimFiyat:number;
}