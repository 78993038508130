<section id="content">
    <figure [ngStyle]="{ 'background-image': 'url(data:image/jpeg;base64,'+currentHaberDetay.gorselBuyukBase64+')' }">
        <div [ngClass]="'breadcrumb'">
            <h1>{{currentHaberDetay.haberBaslik}}</h1>
            <ul id="breadcrumb" itemscope itemtype="http://schema.org/BreadcrumbList">
                <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item" routerLink="/" title="Ana Sayfa"><span  itemprop="name">Ana Sayfa</span></a>
                    <meta itemprop="position" content="1" />
                </li>
                <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a itemprop="item" routerLink="/haberler" title="Haberler"><span  itemprop="name">Haberler</span></a>
                    <meta itemprop="position" content="2" />
                </li>
                <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
                    <meta itemprop="position" content="3" />{{currentHaberDetay.haberBaslik}}</li>
            </ul>
        </div>
    </figure>
    <div id="article-text" [innerHTML]="currentHaberDetay.haberMetni">
        {{currentHaberDetay.haberMetni}}
    </div>
</section>